import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';

class Orders extends ChemComponent {
	constructor(props) {
		super(props);
		
		// start with from date of 120 days ago
		var from120Days = new Date();
		from120Days.setDate(from120Days.getDate() - 120);

		this.state = {
			orderList: this.copyTable(orderList),
			search: {
				CART_ID: '',
				CART_STATUS: [2,5], // Open,Closed
				CREATE_DATE_FROM: this.dateTimeToMVC(from120Days),
				CREATE_DATE_TO: null,
				USER_ID: [],
				CARE_OF: [],
				DELIVERED_BY: [],
				ORDER_BY: ['CART_ID desc']
			},
			showSearch: true,
			searchCount: 0,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onExpandSearch(event) {
		this.mergeState({
			showSearch: true,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();		
	}

	onSubmitSearch(event, newOrderBy) {
		var newOrderList = this.copyTable(orderList);
		var newSearch = this.copyObject(this.state.search);
		if (newOrderBy) newSearch.ORDER_BY = newOrderBy;
		// make a second copy for newOrderList so that the calls
		// to quoteStringArray won't mess up the search fields
		newOrderList.ajax.data = {
			search: this.copyObject(newSearch)
		};
		newOrderList.hold = false;
		this.quoteStringArray(newOrderList.ajax.data.search.CARE_OF);
		this.quoteStringArray(newOrderList.ajax.data.search.DELIVERED_BY);
		this.mergeState({ 
			search: newSearch,
			orderList: newOrderList,
			showSearch: false,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	onClickOrder(cartId) {
		this.props.navigate('/Reuse/Orders/' + cartId);
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			Action: 'Orders',
			columns: exportColumns,
			search: this.state.search
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Orders</div>
						{this.state.showSearch && 
							<ChemEdit parent={this} user={this.props.user} columns={orderSearch} data={this.state.search}
							  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />}
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Reuse/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Reuse/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						{this.state.showSearch ?
							<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button> :
							<Button id="expand" variant="warning" type="button" className="float-end" onClick={(event) => this.onExpandSearch(event)}>Modify Search</Button>
						}
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='Reuse_Orders' table={this.state.orderList} order={this.state.search.ORDER_BY}
				    onResult={(data) => this.onSearchResult(data)} onTableSort={(newOrder) => this.onSubmitSearch(null, newOrder)} offsetHeight='150px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const orderSearch = [
	{ Header: 'ID', accessor: 'CART_ID', row: 0, col: 0 },
	{ Header: 'Status', accessor: 'CART_STATUS', type: 'list', row: 0, col: 1,
		options: [{ value: 2, label: 'Open' },{ value: 5, label: 'Closed' },
			{ value: 6, label: 'Archived' },{ value: 7, label: 'Cancelled' }]},
	{ Header: 'Date From', accessor: 'CREATE_DATE_FROM', type: 'date', row: 0, col: 2 },
	{ Header: 'Date To', accessor: 'CREATE_DATE_TO', type: 'date', row: 0, col: 3 },
	{ Header: 'Placed By', accessor: 'USER_ID', type: 'list', row: 1, col: 0,
		options: { value: 'USER_ID', label: 'USER_NAME', entity: 'REUSE.CART_USERS_VW', order: 'USER_NAME' }},
	{ Header: 'In Care Of', accessor: 'CARE_OF', type: 'list', row: 1, col: 1,
		options: { value: 'CARE_OF', label: 'CARE_OF', entity: 'REUSE.CART_ITEM', order: 'CARE_OF', distinct: true }},
	{ Header: 'Delivered By', accessor: 'DELIVERED_BY', type: 'list', row: 1, col: 2,
		options: { value: 'DELIV_BY', label: 'DELIV_BY', entity: 'REUSE.CART_ITEM', order: 'DELIV_BY', distinct: true }}
];

const orderList = {
	ajax: { 
		url: '/Reuse/OrderSearch',
		data: null,
		accessor: 'Data'
	},
	hold: true,
	pageSize: 20,
	columns: [
		{ Header: 'Order ID', accessor: 'CART_ID', width: 90,
			Cell: props => <HashLink onClick={() => props.parent.onClickOrder(props.value)}>{props.value}</HashLink> },
		{ Header: 'Name', accessor: 'USER_NAME', width: 200 },
		{ Header: 'Order Date', accessor: 'CREATE_DATE', width: 150 },
		{ Header: 'Items', accessor: 'TOTAL_ITEMS', width: 80 },
		{ Header: 'Delivered', accessor: 'DELIV_BY', width: 350,
			Cell: props => props.parent.getCellFragment(props.value ? props.value.trim() : props.value) },
		{ Header: 'Status', accessor: 'STATUS_NAME', width: 100 }	
	]
};

const exportColumns = [
	{ Header: 'Order ID', accessor: 'CART_ID', width: 18 },
	{ Header: 'Name', accessor: 'USER_NAME', width: 40 },
	{ Header: 'Order Date', accessor: 'CREATE_DATE', width: 30 },
	{ Header: 'Items', accessor: 'TOTAL_ITEMS', width: 18 },
	{ Header: 'Delivered', accessor: 'DELIV_BY', width: 40 },
	{ Header: 'Delivery Date', accessor: 'DELIV_DATE', width: 30 },
	{ Header: 'Status', accessor: 'STATUS_NAME', width: 20 }	
];

export default withRouter(Orders);
