import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Dropdown, Col, Container, Row } from "react-bootstrap";
import ChemComponent from "./ChemComponent";

class ChemHeader extends ChemComponent
{
	constructor(props) {
		super(props);
		
		this.state = {
			show: false
		};
	}

	onToggle(isOpen, event, metadata) {
		this.mergeState({ show: !this.state.show });
	}
	
	ifProduction(prodValue, testValue) {
		// it was decided to use test color scheme in production
		return testValue;
	}
	
	render() {
		const backgroundImage = this.ifProduction('linear-gradient(to bottom, #395d7d, #003262)', 'linear-gradient(to bottom, #ede700, #fdb515)');
		const logoImage = this.ifProduction('/images/prod/coc_logo.png', '/images/test/coc_logo.png');
		const menuItemPrefix = this.ifProduction('coc-dropdown-item', 'coc-test-dropdown-item');
		const fontColor = this.ifProduction('white', 'black');
		const variant = this.ifProduction('dark', 'light');
		return (<>
			<Navbar bg={variant} variant={variant} expand={false} style={{ backgroundImage: backgroundImage}} >
			  <Container fluid style={{ fontFamily: 'Verdana, Arial, Helvetica, sans-serif', fontWeight: 'bold', color: fontColor }} >
				<Navbar.Brand href="#home"><img src={logoImage} alt="College of Chemistry" /></Navbar.Brand>
				<Col style={{ fontSize: '30px', textTransform: 'uppercase', textAlign: 'center' }}>{this.props.title}</Col>
				<Col xs={2} style={{ fontSize: '12px', textAlign: 'right', paddingRight: '6px' }}>{this.getUser().name}</Col>
				<Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => this.mergeState({ show: !this.state.show })} />
			  </Container>
			</Navbar>
			<Container fluid>
			  <Row>
			    <Col style={{ textAlign: 'right' }}>
			      <Dropdown id='navDropdown' show={this.state.show}
				      onToggle={(isOpen, event, metadata) => this.onToggle(isOpen, event, metadata)}>
			        <Dropdown.Toggle as='span' bsPrefix='noStyling' />
			        <Dropdown.Menu variant="light">
					  {!this.props.hideMenu && <Dropdown.Item onClick={() => this.props.toggleFullScreen(!this.props.fullScreen)} bsPrefix={menuItemPrefix}>{this.props.fullScreen ? 'Exit Full Screen' : 'Full Screen Mode'}</Dropdown.Item> }
			          <Dropdown.Item onClick={() => this.logout()} bsPrefix={menuItemPrefix}>Logout</Dropdown.Item>
			          {!this.props.hideMenu && <Dropdown.Item onClick={() => this.props.showHelp()} bsPrefix={menuItemPrefix}>Help</Dropdown.Item> }
			          {!this.props.hideMenu && <Dropdown.Item onClick={() => this.showAbout()} bsPrefix={menuItemPrefix}>About</Dropdown.Item> }
			        </Dropdown.Menu>
		          </Dropdown>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

export default ChemHeader;