import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Transaction extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			transactions: [],
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadTransactions();
	}
	
	// so I can rearrange columns without breaking everything
	getColumnMap(columns) {
		return {
			serviceName: this.findColumn(columns, 'SERVICE_NAME'),
			transDate: this.findColumn(columns, 'TRANS_DATE'),
			piName: this.findColumn(columns, 'PI_NAME'),
			speedtype: this.findColumn(columns, 'SPEEDTYPE'),
			poNum: this.findColumn(columns, 'PO_NUM'),
			userName: this.findColumn(columns, 'USER_NAME'),
			laborHours: this.findColumn(columns, 'LABOR_HOURS'),
			materialCost: this.findColumn(columns, 'MATERIAL_COST'),
			description: this.findColumn(columns, 'DESCRIPTION'),
			billFlag: this.findColumn(columns, 'BILL_FLAG'),
			transferFlag: this.findColumn(columns, 'TRANSFER_FLAG'),
			deleteFlag: this.findColumn(columns, 'DELETE_FLAG'),
			comments: this.findColumn(columns, 'COMMENTS'),
			dateBilled: this.findColumn(columns, 'DATE_BILLED'),
			modified: this.findColumn(columns, 'MODIFIED')
		};			
	}
	
	findColumn(columns, accessor) {
		for (var i = 0; i < columns.length; i++) {
			if (columns[i].accessor === accessor) return i;
		}
		return -1;
	}

	loadTransactions(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/EditTransactions',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				service: this.props.params.serviceId,
				job: this.props.params.jobId
			}
		}).done(function (data) {
			if (data.Success) {
				var transactions = [];
					
				for (var i = 0; i < data.Data.length; i++) {
					
					var transaction = data.Data[i];
					var columns = self.copyColumns(transactionColumns);

					// convert flags
					transaction.BILL_FLAG = transaction.BILL_FLAG === 'Y';
					transaction.DELETE_FLAG = transaction.DELETE_FLAG === 'Y';
					transaction.TRANSFER_FLAG = false;
					transaction.LEGACY_FLAG = transaction.LEGACY_FLAG === 'Y';
					
					// convert dates
					transaction.TRANS_DATE = self.dateTimeToMVC(self.getDate(transaction.TRANS_DATE));
					
					// compose created/modified
					transaction.MODIFIED = self.composeChangeInfo(transaction.MODIF_BY, transaction.MODIF_DATE);
					
					// save original speedtype for use in update
					transaction.OLD_SPEEDTYPE = transaction.SPEEDTYPE;
					
					// get column mappings
					var colidx = self.getColumnMap(transactionColumns);
					
					// convert service ID to number
					var serviceId = +self.props.params.serviceId;
					
					// if this is a legacy transaction
					if (transaction.LEGACY_FLAG) {
						// adjust columns based on service ID
						if (serviceId === 10) {
							// make transaction date editable
							delete columns[colidx.transDate].Cell;

							// hide labor hours
							columns[colidx.laborHours].editable = false;
							columns[colidx.laborHours].Header = '';
							columns[colidx.laborHours].Cell = props => <></>;
							
							// change material cost to quantity
							columns[colidx.materialCost].Header = 'Quantity';
							columns[colidx.materialCost].subscript = '@ $' + self.isNumeric(transaction.MATERIAL_COST) ? (+transaction.MATERIAL_COST).toFixed(2) : '0.00';
							
							// make bill flag non-editable since dry ice doesn't have this feature
							columns[colidx.billFlag].editable = false;
						} else {
							// make transaction date read-only
							delete columns[colidx.transDate].type;
							columns[colidx.transDate].editable = false;
							
							// make user name read only
							columns[colidx.userName].editable = false;
							
							// make material cost read only
							columns[colidx.materialCost].editable = false;
						}
							
						if (serviceId > 19 && serviceId < 30) {
							// make speedtype read only
							delete columns[colidx.speedtype].type;
							delete columns[colidx.speedtype].options;
							columns[colidx.speedtype].editable = false;
						} else {
							// if role is indicated
							if (!self.isEmpty(transaction.ROLE_ID)) {
								// filter speedtypes by role
								columns[colidx.speedtype].options.search = { Attribute: 'ROLE_ID', Operator: '=', LongValue: transaction.ROLE_ID };
							}
						}
						
						if (serviceId > 9 && serviceId < 31) {
							// hide PO number
							columns[colidx.poNum].editable = false;
							columns[colidx.poNum].Header = '';
							columns[colidx.poNum].Cell = props => <></>;
						}
						
						if (serviceId > 3) {
							// hide transfer flag
							delete columns[colidx.transferFlag].type;
							columns[colidx.transferFlag].editable = false;
							columns[colidx.transferFlag].Header = '';
							columns[colidx.transferFlag].Cell = props => <></>;
						}
						
						if (serviceId > 18 && serviceId < 31) {
							// hide delete flag
							delete columns[colidx.deleteFlag].type;
							columns[colidx.deleteFlag].editable = false;
							columns[colidx.deleteFlag].Header = '';
							columns[colidx.deleteFlag].Cell = props => <></>;
						}
						
						if (serviceId < 4) {
							// hide comments
							delete columns[colidx.comments].type;
							columns[colidx.comments].editable = false;
							columns[colidx.comments].Header = '';
							columns[colidx.comments].Cell = props => <></>;
						}
						
						if (self.props.user.crs.userAccessID !== 1) {
							// hide date billed
							columns[colidx.dateBilled].Header = '';
							columns[colidx.dateBilled].Cell = props => <></>;
						}
					} else {
						var editIdx = -1;
						// adjust based on table name
						if (['recharges.shops_recharges','liquid_air.meter_reading','liqair.meter_reading','FAS'].includes(transaction.RCH_TABLE)) {
							// make the bill flag editable
							editIdx = colidx.billFlag;
						} else if (['recharges.dry_ice','Demurrage.dem_trans','liquid_air.requests','liquid_air.ln2_transaction','STORES.STORE_SALE'].includes(transaction.RCH_TABLE)) {
							// make the transaction date editable
							editIdx = colidx.transDate;
							
							// remove cell formatting so date picker will be displayed
							delete columns[colidx.transDate].Cell;
						} else {
							self.showAlert('Unknown Recharge Table', 'Unknown recharge table "' + transaction.RCH_TABLE + '"');
						}
						
						// mark all rows uneditable except the one we chose for editing
						for (var c = 0; c < columns.length; c++) {
							if (c !== editIdx) columns[c].editable = false;
						}
					}
					
					transactions.push({
						data: transaction,
						columns: columns
					});
				}
					
				self.mergeState({
					transactions: transactions,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
		
	onChange(index, accessor, value) {
		var transactions = [];
		for (var i = 0; i < this.state.transactions.length; i++) {
			transactions.push(this.copyTable(this.state.transactions[i]));
		}
		this.setByAccessor(transactions[index].data, accessor, value);
		this.mergeState({ transactions: transactions, renderKey: this.state.renderKey + 1 });
	}
	
	validateTransaction(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event, index) {
		var self = this;		
		
		// make copy of data
		var transaction = this.copyObject(this.state.transactions[index].data);
		
		// convert flags
		transaction.BILL_FLAG = transaction.BILL_FLAG ? 'Y' : null;
		transaction.DELETE_FLAG = transaction.DELETE_FLAG ? 'Y' : null;
		transaction.TRANSFER_FLAG = transaction.TRANSFER_FLAG ? 'Y' : null;
		transaction.LEGACY_FLAG = transaction.LEGACY_FLAG ? 'Y' : null;
		
		// convert dates
		transaction.TRANS_DATE = this.dateTimeToMVC(this.getDate(transaction.TRANS_DATE));
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/CRS/UpdateTransaction',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, transaction: transaction }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						{data.RowsAffected} transactions updated for Service {transaction.SERVICE_ID}, Job <Link to={'/CRS/Transaction/' + transaction.SERVICE_ID + '/' + transaction.JOBNO}>{transaction.JOBNO}</Link>
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
		    {this.state.transactions.length === 0 && 
			  <Row>
			    <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
			      No transactions found for Service {this.props.params.serviceId}, Job {this.props.params.jobId}
			    </Col>
			  </Row>
			}
		    {this.state.transactions.map((transaction, index) => {
			  return(
			    <Row key={index}>
			      <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				    <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Service {transaction.data.SERVICE_ID}, Job {transaction.data.JOBNO}</div>
				    <ChemEdit parent={this} columns={transaction.columns} data={transaction.data} user={this.props.user} renderKey={this.state.renderKey} 
				      onChange={(accessor, value) => this.onChange(index, accessor, value)} onSubmit={(event) => this.onSubmit(event, index)}
				      validate={(event, columns, data) => this.validateTransaction(event, columns, data)}>
				      <Form.Group>
					    <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
				      </Form.Group>
				    </ChemEdit>
			      </Col>
			    </Row>
		      );
			})}
		  </Container>
		</>);
	}
}

const transactionColumns = [
	{ Header: 'Service Name', accessor: 'SERVICE_NAME', row: 0, col: 0, editable: false },
	{ Header: 'Transaction Date', accessor: 'TRANS_DATE', type: 'date', row: 0, col: 1,
		Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
	{ Header: 'PI Name', accessor: 'PI_NAME', row: 0, col: 2, editable: false },
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', type: 'select', row: 1, col: 0,
		options: { value: 'SPEEDTYPE', label: 'SPEEDTYPE_INFO', entity: 'RECHARGES.TRANSACTION_CC_SELECT_VW', order: ['SPEEDTYPE','SPEEDTYPE_INFO'] }},
	{ Header: 'PO Num', accessor: 'PO_NUM', row: 1, col: 1 },
	{ Header: 'User Name', accessor: 'USER_NAME', row: 1, col: 2 },
	{ Header: 'Labor Hours', accessor: 'LABOR_HOURS', row: 2, col: 0 },
	{ Header: 'Material Cost', accessor: 'MATERIAL_COST', row: 2, col: 1 },
	{ Header: 'Description', accessor: 'DESCRIPTION', row: 2, col: 2, editable: false },
	{ Header: 'Bill', accessor: 'BILL_FLAG', type: 'checkbox', row: 3, col: 0 },
	{ Header: 'Transfer', accessor: 'TRANSFER_FLAG', type: 'checkbox', row: 3, col: 1 },
	{ Header: 'Delete', accessor: 'DELETE_FLAG', type: 'checkbox', row: 3, col: 2 },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 4, col: 0 },
	{ Header: 'Date Billed', accessor: 'DATE_BILLED', row: 4, col: 1, editable: false },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 4, col: 2, editable: false }
];

export default withRouter(Transaction);
