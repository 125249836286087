import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import { Col, Container, Row, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

class Checkout extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			screen: screens.swipe,
			swipe: {
				CARD_MAG: ''
			},
			checkout: {
				SWIPE_PERSON_ID: null,
				SWIPE_PI_ROLE_ID: null,
				CARD_MAG: '',
				PERSON_ID: null,
				PI_ROLE_IDS: [],
				PI_ROLE_ID: null,
				SPEEDTYPE: null,
				QTY: null,
				STOCK_NUMBER: '',
				checkout_details: [],
				pi_map: {},
			},
			confirm: {
				SALE_ID: null,
				GRAND_TOTAL: null
			},
			timerStart: null,
			timerID: null,
			secondsRemaining: null,
			renderKey: 0
		}
	}	
	
	updateTimer() {
		// get number of seconds elapsed since timerStart
		var secondsElapsed = (new Date().getTime() - this.state.timerStart.getTime())/1000;
		var secondsRemaining = this.getTimerSeconds() - secondsElapsed;
		if (secondsRemaining <= 0) {
			// close any modal dialog that might be open
			this.cancelModalDialog(() => {			
				// if there is a speedtype and any items
				if (!this.isEmpty(this.state.checkout.SPEEDTYPE) &&
					this.state.checkout.checkout_details.length > 0) {
					this.onSubmit();
				} else {
					this.clearTimerAndLogout();
				}
			});
		} else {
			this.mergeState({ secondsRemaining: secondsRemaining });
		}
	}

	resetTimer() {
		this.mergeState({
			timerStart: new Date(),
			secondsRemaining: this.getTimerSeconds()
		});
	}

	clearTimerAndLogout() {
		var self = this;
		
		if (this.state.checkout.checkout_details.length > 0) {
			this.showOKCancel('Purchase Incomplete', 'Your cart is not empty. Are you purchasing these items?', (okClicked) => {
				if (okClicked) {
					self.onSubmit();
				} else {
					if (this.state.timerID !== null) clearInterval(this.state.timerID);
					this.logout();
				}
			});
		} else {
			if (this.state.timerID !== null) clearInterval(this.state.timerID);
			this.logout();
		}
	}
	
	getTimerSeconds() {
		return this.state.screen === screens.confirm ? timerSeconds.confirm : timerSeconds.checkout;
	}
	
	componentDidMount() {
		if (this.props.logOut) this.logout();
	}

	componentDidUpdate(prevProps) {
		var self = this;
		var clearState = false;
		
		// if we are logging out
		if (this.props.logOut) {
			this.logout();
			clearState = true;
		} else {
			// if the user has just logged in
			if (prevProps.user.uid !== this.props.user.uid && this.props.user.uid > 0) {
				// check in to stores
				this.ajax({
					type: 'post',
					url: this.getConfig().host + '/Stores/CheckIn',
					data: { __RequestVerificationToken: this.props.user.antiForgeryToken, cardmag: this.state.checkout.CARD_MAG }
				}).done(function (data) {
					if (data.Success) {
						var newState = data.Data;
						
						newState.screen = screens.checkout;					
						newState.checkout = {
							SWIPE_PERSON_ID: newState.swipe_user.PERSON_ID,
							SWIPE_PI_ROLE_ID: newState.swipe_user.PI_ROLE_ID,
							PERSON_ID: newState.swipe_user.PERSON_ID,
							PI_ROLE_IDS: newState.swipe_user.PI_ROLE_IDS,
							PI_ROLE_ID: newState.swipe_user.PI_ROLE_ID,
							SPEEDTYPE: newState.swipe_user.DEFAULT_CC,
							checkout_details: []
						};
						newState.timerStart = new Date();
						// stop any existing timer
						if (self.state.timerID !== null) clearInterval(self.state.timerID);
						newState.timerID = setInterval(() => self.updateTimer(), 1000);
						newState.secondsRemaining = self.getTimerSeconds();
						
						self.mergeState(newState);
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			} else if (prevProps.user.uid !== this.props.user.uid && this.props.user.uid <= 0) {
				clearState = true;
			}
		}
		
		if (clearState) {
			this.mergeState({
				swipe: {
					CARD_MAG: ''
				},
				checkout: {
					SWIPE_PERSON_ID: null,
					SWIPE_PI_ROLE_ID: null,
					CARD_MAG: '',
					PERSON_ID: null,
					PI_ROLE_IDS: [],
					PI_ROLE_ID: null,
					SPEEDTYPE: null,
					QTY: null,
					STOCK_NUMBER: '',
					checkout_details: [],
					pi_map: {},
				},
				confirm: {
					SALE_ID: null,
					GRAND_TOTAL: null
				}
			});
		}
	}

	onChange(accessor, value) {
		var newState = {
			timerStart: new Date(),
			secondsRemaining: this.getTimerSeconds(),
			checkout: this.copyObject(this.state.checkout),
			renderKey: this.state.renderKey + 1
		};
		this.setByAccessor(newState.checkout, accessor, value);
		if (accessor === 'checkout_details') {
			newState.editorsOpen = this.setEditorStatus(accessor, false);
		} else if (accessor === 'PERSON_ID') {
			var piInfo = this.state.pi_map['' + value] || [];
			newState.checkout.PI_ROLE_IDS = [];
			newState.checkout.PI_ROLE_ID = null;
			newState.checkout.SPEEDTYPE = null;
			for (var i = 0; i < piInfo.length; i++) {
				var pipeIdx = piInfo[i].indexOf('|');
				var pi_role_id = piInfo[i].substring(0, pipeIdx);
				var speedtype = piInfo[i].substring(pipeIdx + 1);
				if (i === 0) {
					newState.checkout.PI_ROLE_ID = pi_role_id;
					newState.checkout.SPEEDTYPE = speedtype;
				}
				newState.checkout.PI_ROLE_IDS.push(pi_role_id);
			}
		}
		this.mergeState(newState);
	}
	
	addItem(event) {
		var inputErrors = [];
		if (!this.isNumeric(this.state.checkout.QTY)) inputErrors.push('a numeric quantity');
		if (this.isEmpty(this.state.checkout.STOCK_NUMBER)) inputErrors.push('a stock number');
		
		if (inputErrors.length > 0) {
			this.showAlert('Input Error', 'Please enter ' + inputErrors.join(' and ') + '.');
		} else {
			var self = this;
			
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Stores/AddCheckoutItem',
				overlay: true,
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, qty: this.state.checkout.QTY, stockno: this.state.checkout.STOCK_NUMBER }
			}).done(function (data) {
				if (data.Success) {
					// add new item to line item array
					var new_details = self.copyObject(self.state.checkout.checkout_details);
					var new_detail = data.Data[0];
					new_detail.id = new_details.length;
					new_detail.LINE_ID = new_detail.id + 1;
					new_details.push(new_detail);
					self.mergeState({
						checkout: {
							QTY: '',
							STOCK_NUMBER: '',						
							checkout_details: new_details
						},
						timerStart: new Date(),
						secondsRemaining: self.getTimerSeconds()
					}, () => {
						document.getElementById('QTY').focus();
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}
	
	removeItem(i) {
		var self = this;
		var qty = this.state.checkout.checkout_details[i].QTY;
		var stockNo = this.state.checkout.checkout_details[i].STOCK_NO;
		this.showOKCancel('Confirm Remove', 'Remove qty ' + qty + ' of item ' + stockNo + '?', (okClicked) => {
			var newState = self.copyObject(self.state);
			// copyObject doesn't copy javascript Date objects correctly
			if (self.state.timerStart) newState.timerStart = new Date(self.state.timerStart.getTime());
			newState.checkout.checkout_details.splice(i, 1);
			for (var newIdx = 0; newIdx < newState.checkout.checkout_details.length; newIdx++) {
				newState.checkout.checkout_details[newIdx].id = newIdx;
				newState.checkout.checkout_details[newIdx].LINE_ID = newIdx + 1;
			}
			self.setState(newState, () => {
				setTimeout(() => {
					document.getElementById('QTY').focus();
				}, 200);
			});
		});
	}
		
	onSubmit(event) {
		if (this.state.checkout.checkout_details.length === 0) {
			this.showAlert('No Items Selected', "Please add an item for purchase.");
		} else if (this.isEmpty(this.state.checkout.SPEEDTYPE)) {
			this.showAlert('No Speedtype Selected', "Please select a speedtype before proceeding.");
		} else {
			var self = this;
			
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Stores/CheckOut',
				overlay: true,
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, checkout: this.state.checkout }
			}).done(function (data) {
				if (data.Success) {
					// clear timer, and clear speedtype/items so we don't repeat the sale every 30 seconds!
					self.mergeState({
						checkout: {
							SPEEDTYPE: null,
							checkout_details: []
						},
						timerStart: new Date(),
						secondsRemaining: 30,
						screen: screens.confirm,
						confirm: data.Data
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}

	onChangeSwipe(accessor, value) {
		var swipe = this.copyObject(this.state.swipe);
		this.setByAccessor(swipe, accessor, value);
		this.mergeState({ swipe: swipe });
	}

	onSubmitSwipe(event) {
		this.props.logIn(this.state.swipe.CARD_MAG);
		// move cardmag to user where it will not be visible on the screen
		this.mergeState({ swipe: { CARD_MAG: '' }, checkout: { CARD_MAG: this.state.swipe.CARD_MAG }});
	}

	render() {
		
		// create args for receipt pdf icon
		var receiptData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			saleid: this.state.confirm.SALE_ID
		});

		// calculate numbers for timer
		var minutesRemaining = Math.floor(this.state.secondsRemaining / 60);
		var secondsRemaining = Math.round(this.state.secondsRemaining % 60);
		if (secondsRemaining === 60) { // huh??
			minutesRemaining += 1;
			secondsRemaining = 0;
		}
		var timer = minutesRemaining + (secondsRemaining < 10 ? ':0' : ':') + secondsRemaining;

		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col xs={3}>
						<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Storeroom Checkout System</div>
					  </Col>
					  <Col xs={2}>
						{this.state.screen !== screens.swipe && <table>
						  <tbody>
						    <tr>
							  <td>Time Remaining:&nbsp;&nbsp;</td>
							  <td align='right'>{timer}</td>
							</tr>
						  </tbody>
						</table>}
					  </Col>
					  <Col xs={7}>
					  	{this.state.screen !== screens.swipe && 
						  <Button style={{ marginTop: '-6px' }} id="reset_timer" variant="warning" type="button" onClick={(event) => this.resetTimer()}>Add Time</Button>}
					  </Col>
					</Row>
					{this.state.screen === screens.swipe && <>
					<Row>
					  <Col style={{ paddingBottom: '10px' }}>
					    This computer is for use of the Chem Storeroom Checkout ONLY. If you require assistance, please ask a Storeroom attendant, or contact chem_storeroom@berkeley.edu. Thank you.
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={swipeColumns} data={this.state.swipe} user={this.props.user} renderKey={this.state.renderKey} autoSubmit={true}
						   onChange={(accessor, value) => this.onChangeSwipe(accessor, value)} onSubmit={(event) => this.onSubmitSwipe(event)} editable={true} />
					  </Col>
					</Row>
					</>}
					{this.state.screen === screens.checkout && <>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={checkoutColumns} data={this.state.checkout} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.addItem(event)}>
						  <Form.Group>
							<Button id="additem" variant="warning" type="submit" className="float-end">Add Item</Button>
						  </Form.Group>
						</ChemEdit>   
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingBottom: '10px' }}>
						<h6>Line Items</h6>
						<ChemTable parent={this} user={this.props.user} renderKey={this.state.renderKey} data={this.state.checkout.checkout_details}
						  columns={detailTableColumns} tableName='Stores_Checkout_Detail' />						
					  </Col>
					</Row>
					<Row>
					  <Col xs={9} style={{ paddingTop: '8px' }}>
						{this.state.checkout.checkout_details.length > 0 && <>
							<div style={{ color: 'red', fontWeight: 'bold' }}>
								Please remember to click 'Purchase' or 'Log Out' when you are finished. Any items left in cart will be automatically purchased when timer expires!
							</div>
						</>}
					  </Col>
					  <Col xs={1}>
						<Button id="save" variant="warning" type="button" className="float-end"
							onClick={(event) => this.onSubmit(event)}>Purchase</Button>
					  </Col>
					  <Col xs={2}>
						<Button id="chk_logout" variant="warning" type="button" className="float-end"
							onClick={(event) => this.clearTimerAndLogout()}>Log Out</Button>
					  </Col>
					</Row>
					</>}
					{this.state.screen === screens.confirm &&
					<Row>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Receipt'} method='POST'>
							{receiptData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Print Receipt" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={10} style={{ paddingBottom: '20px', paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
						Sale {this.state.confirm.SALE_ID} completed. Your grand total is {this.state.confirm.GRAND_TOTAL}. Click the PDF icon to print your receipt. Please remember to log out when you are finished.
						{!this.isEmpty(this.state.confirm.PRINT_ADVISORY) && <>
							<br />{this.state.confirm.PRINT_ADVISORY}
						</>}
					  </Col>
					  <Col xs={1}>
						<Button id="logout" variant="warning" type="button" className="float-end" onClick={(event) => this.clearTimerAndLogout()}>Log Out</Button>
					  </Col>
				    </Row>}
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const timerSeconds = {
	checkout: 180,
	confirm: 30
};

const screens = {
	swipe: 'Swipe Card',
	checkout: 'Checkout',
	confirm: 'Confirm'
};

const swipeColumns = [
	{ Header: 'Please Swipe Card to Start', accessor: 'CARD_MAG', autofocus: true, autocomplete: false }
];

const checkoutColumns = [
	{ Header: 'User', accessor: 'PERSON_ID', type: 'select', row: 0, col: 0,
		options: { value: 'PERSON_ID', label: 'USER_NAME', entity: 'STORES.CHECKOUT_USER_SELECT_VW', order: ['SWIPED_AT_TOP','SORT_USER_NAME'],
			search: { Operator: 'or', Children: [
				{ Operator: 'and', Children: [
					{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'SWIPE_PERSON_ID' },
					{ Attribute: 'SWIPED_AT_TOP', Operator: '=', IntValue: '0' }
				]},
				{ Operator: 'and', Children: [
					{ Attribute: 'PERSON_ID', Operator: '!=', LongAccessor: 'SWIPE_PERSON_ID' },
					{ Attribute: 'SWIPED_AT_TOP', Operator: '=', IntValue: '1' }
				]}
	]}}},
	{ Header: 'PI', accessor: 'PI_ROLE_ID', type: 'select', row: 0, col: 1,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'STORES.CHECKOUT_PI_SELECT_VW', order: ['USER_PI_FIRST','SORT_PI_NAME'],
			search: { Operator: 'or', Children: [
				{ Operator: 'and', Children: [
					{ Attribute: 'ROLE_ID', Operator: 'in', LongListAccessor: 'PI_ROLE_IDS' },
					{ Attribute: 'USER_PI_FIRST', Operator: '=', IntValue: '0' }
				]},
				{ Operator: 'and', Children: [
					{ Attribute: 'ROLE_ID', Operator: 'not in', LongListAccessor: 'PI_ROLE_IDS' },
					{ Attribute: 'USER_PI_FIRST', Operator: '=', IntValue: '1' }
				]}
	]}}},
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', type: 'select', row: 0, col: 2,
		options: { value: 'COST_CENTER', label: 'COST_CENTER_DISPLAY', entity: 'STORES.CHECKOUT_SPEEDTYPE_SELECT_VW', order: 'COST_CENTER',
			search: { Attribute: 'ROLE_ID', Operator: '=', LongAccessor: 'PI_ROLE_ID'
	}}},
	{ Header: 'Qty', accessor: 'QTY', row: 1, col: 0, autofocus: true },
	{ Header: 'Stock Number', accessor: 'STOCK_NUMBER', row: 1, col: 1 }
];

const detailTableColumns = [
	{ Header: 'Remove', accessor: 'id', width: 100, Cell: props =>
		<Button id={'remove_' + props.value} variant="warning" type="button" onClick={(event) => props.parent.removeItem(props.value)}>Remove</Button> },
	{ Header: 'Line', accessor: 'LINE_ID', width: 50 },
	{ Header: 'Qty', accessor: 'QTY', width: 70, align: 'right' },
	{ Header: 'Stock Number', accessor: 'STOCK_NO', width: 110 },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 300 },
	{ Header: 'Unit', accessor: 'UNIT_OF_MEASURE', width: 70 },
	{ Header: 'Price', accessor: 'UNIT_COST', width: 80, align: 'right', Cell: props => props.parent.formatCurrency(props.value) },
	{ Header: 'Total', accessor: 'SUB_TOTAL', width: 80, align: 'right', Cell: props => props.parent.formatCurrency(props.value) },
];

export default withRouter(Checkout);
