import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemTable from '../ChemTable';
import { ArrowsCollapseVertical, ArrowsExpandVertical, Trash } from 'react-bootstrap-icons';

class ShoppingCart extends ChemComponent {
  	constructor(props) {
		super(props);

		this.state = {
			renderKey: 1
		};
	}
	
	onDelete(itemId) {
		this.props.removeFromCart(itemId);
	}
	
	onClickCollapse() {
		if (this.props.menuWidth > 1) this.props.setMenuWidth(this.props.menuWidth - 1);
	}
	
	onClickExpand() {
		if (this.props.menuWidth < 12) this.props.setMenuWidth(this.props.menuWidth + 1);
	}
	
	onClearCart() {
		this.props.removeFromCart();
	}
	
	render() {
		return (<>
			<Container fluid style={{ marginTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
			  <Row>
				<Col xs={8} style={{ marginTop: '6px'}}>
				  <div style={{ marginLeft: '6px', fontWeight: 'bold', fontSize: '12pt' }}>Shopping Cart</div>
				</Col>
				<Col xs={2}style={{ marginTop: '6px'}}>
				  <ArrowsCollapseVertical style={{ cursor: 'pointer' }} onClick={() => this.onClickCollapse()} />
				</Col>
				<Col xs={2}style={{ marginTop: '6px'}}>
				  <ArrowsExpandVertical style={{ cursor: 'pointer' }} onClick={() => this.onClickExpand()} />
				</Col>
			  </Row>
			  <Row>
			    <Col style={{ marginTop: '6px' }}>
				  <ChemTable renderKey={this.state.renderKey} parent={this} columns={cartColumns} data={this.props.shoppingCart} name='Reuse_ShoppingCart' />
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <Button id="checkout" variant="warning" type="button" className="float-end" 
					onClick={(event) => this.onCheckout(event)}>Checkout</Button>
				  <Button style={{ marginRight: '10px' }} id="clear" variant="warning" type="button" className="float-end" 
					onClick={(event) => this.onClearCart(event)}>Clear Cart</Button>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const cartColumns = [
	{ Header: '', accessor: 'ITEM_ID', Cell: props => <Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.onDelete(props.value)} />, width: 25 },
	{ Header: '#', accessor: 'LINE_ID', width: 25 },
	{ Header: 'Item', accessor: 'ITEM_DESC', width: 700 }
];

export default ShoppingCart;
