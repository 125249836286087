import React from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';

class Order extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			shopping_cart: {},
			unfilled: [],
			filled: [],
			cartColumns: this.copyColumns(cartColumns),
			unfilledColumns: this.copyColumns(unfilledColumns),
			filledColumns: this.copyColumns(filledColumns),
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadOrder();
	}

	loadOrder(event) {
		var self = this;

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Reuse/GetOrder',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				cartid: this.props.params.cartId
			}
		}).done(function (data) {
			if (data.Success) {
				var shopping_cart = data.Data.shopping_cart;
				var unfilled = data.Data.unfilled;
				var filled = data.Data.filled;
				
				// convert from mvc to js
				self.mvc2js(shopping_cart, self.state.cartColumns);
				self.mvc2js(unfilled, self.state.unfilledColumns);
				self.mvc2js(filled, self.state.filledColumns);
					
				self.mergeState({
					shopping_cart: shopping_cart,
					unfilled: unfilled,
					filled: filled,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	onRemove(event) {
		this.props.removeFromCart(this.state.inventory.ITEM_ID);
	}
		
	onSubmit(event) {
		this.props.addToCart(this.state.inventory);
	}
	
	onClickTabItem(tab) {
		this.props.navigate('/Reuse/Bottles/' + this.props.params.itemId + '/' + tab);
	}

	canClickTabItem(tab) {
		return true;
	}
	
	render() {		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Order {this.state.shopping_cart.CART_ID}</div>
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingBottom: '10px' }}>
						<ChemEdit parent={this} user={this.props.user} columns={this.state.cartColumns} data={this.state.shopping_cart} renderKey={this.state.renderKey} editable={false} />
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemTable parent={this} user={this.props.user} name='Reuse_Order_Unfilled' columns={unfilledColumns} data={this.state.unfilled} renderKey={this.state.renderKey} />
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemTable parent={this} user={this.props.user} name='Reuse_Order_Filled' columns={filledColumns} data={this.state.filled} renderKey={this.state.renderKey} />
					  </Col>
					</Row>
					<Form.Group as={Row}>
					  <Col>
					    <Button id="save" variant="warning" type="button" className="float-end" 
							onClick={(event) => this.onSubmit(event)}>Save</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const cartColumns = [
	{ Header: 'Group', accessor: 'RES_GROUP', row: 0, col: 0 },
	{ Header: 'Placed', accessor: 'CREATE_DATE', row: 0, col: 1 }
];

const unfilledColumns = [
	{ Header: 'Bottle', accessor: 'BOTTLE_ID' },
	{ Header: 'Chemical', accessor: 'CHEM_NAME' },
	{ Header: 'CAS Number', accessor: 'CAS_NUMBER' }
];

const filledColumns = [
	{ Header: 'Bottle', accessor: 'BOTTLE_ID' },
	{ Header: 'Chemical', accessor: 'CHEM_NAME' },
	{ Header: 'CAS Number', accessor: 'CAS_NUMBER' }
];

export default withRouter(Order);
