import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Rooms extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			ROOM_ID: null,
			ROOM_NAME: null,
			ROOM_NUMBER: '',
			ROOM_LEVEL: null,
			BUILDING_ID: null,
			DEPARTMENT_ID: null,
			ROOM_FUNCTION: null,
			SQUARE_FOOTAGE: '',
			ROLE_ID: null,
			UNIT_ID: null,
			ROOM_PHONE: ''
		};
		
		var initialRoomList = this.copyRoomList();
		initialRoomList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			roomList: initialRoomList,
			search: search,
			searchCount: null
		};
	}
	
	copyRoomList() {
		var roomListCopy = this.copyObject(roomList);
		for (var i = 0; i < roomList.columns.length; i++) {
			if (roomList.columns[i].Header) {
				roomListCopy.columns[i].Header = roomList.columns[i].Header;
			}
			if (roomList.columns[i].Cell) {
				roomListCopy.columns[i].Cell = roomList.columns[i].Cell;
			}
		}
		return roomListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newRoomList = this.copyRoomList();
		newRoomList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			roomList: newRoomList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.ROOM_ID)) search.Children.push({ Attribute: 'ROOM_ID', Operator: '=', LongValue: data.ROOM_ID });
		if (!this.isEmpty(data.ROOM_NAME)) search.Children.push({ Attribute: 'ROOM_NAME', Operator: '=', StringValue: data.ROOM_NAME });
		if (!this.isEmpty(data.ROOM_NUMBER)) search.Children.push({ Attribute: 'ROOM_NUMBER', Operator: 'like', StringValue: data.ROOM_NUMBER + '%' });
		if (!this.isEmpty(data.ROOM_LEVEL)) search.Children.push({ Attribute: 'ROOM_LEVEL', Operator: '=', StringValue: data.ROOM_LEVEL });
		if (!this.isEmpty(data.BUILDING_ID)) search.Children.push({ Attribute: 'BUILDING_ID', Operator: '=', LongValue: data.BUILDING_ID });
		if (!this.isEmpty(data.DEPARTMENT_ID)) search.Children.push({ Attribute: 'DEPARTMENT_ID', Operator: '=', LongValue: data.DEPARTMENT_ID });
		if (!this.isEmpty(data.ROOM_FUNCTION)) search.Children.push({ Attribute: 'ROOM_FUNCTION', Operator: '=', StringValue: data.ROOM_FUNCTION });
		if (this.isNumeric(data.SQUARE_FOOTAGE)) {
			search.Children.push({ Attribute: 'SQUARE_FOOTAGE', Operator: '>=', DecimalValue: +data.SQUARE_FOOTAGE - 5 });
			search.Children.push({ Attribute: 'SQUARE_FOOTAGE', Operator: '<=', DecimalValue: +data.SQUARE_FOOTAGE + 5 });
		}
		if (!this.isEmpty(data.ROLE_ID)) search.Children.push({ Attribute: 'ROLE_ID', Operator: '=', LongValue: data.ROLE_ID });
		if (!this.isEmpty(data.UNIT_ID)) search.Children.push({ Attribute: 'UNIT_ID', Operator: '=', LongValue: data.UNIT_ID });
		if (!this.isEmpty(data.ROOM_PHONE)) search.Children.push({ Attribute: 'ROOM_PHONE', Operator: '=', StringValue: data.ROOM_PHONE });
		
		// if nothing was selected, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addRoom() {
		this.props.navigate('/BuildingAccess/Rooms/new');
	}
	
	editRoom(roomId) {
		this.props.navigate('/BuildingAccess/Rooms/' + roomId);		
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Rooms',
			columns: exportColumns,
			entity: 'ROOM_SEARCH_VW',
			search: this.constructSearch(this.state.search),
			order: ['BUILDING_NAME', 'ROOM_LEVEL', 'ROOM_NAME']
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Rooms</span>
					<ChemEdit parent={this} columns={roomSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_Rooms' table={this.state.roomList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='300px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const roomSearch = [
	{ Header: 'Room', accessor: 'ROOM_ID', type: 'select', row: 0, col: 0,
		options: { value: 'ROOM_ID', label: 'LOCATION', entity: 'ROOMS_VW', order: 'LOCATION' } },
	{ Header: 'Name', accessor: 'ROOM_NAME', type: 'select', row: 0, col: 1,
		options: { value: 'ROOM_NAME', label: 'ROOM_NAME', entity: 'ROOM_INFO', order: 'ROOM_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'ROOM_NAME', Operator: 'is not null' },
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' }]}}},
	{ Header: 'Number', accessor: 'ROOM_NUMBER', row: 0, col: 2 },
	{ Header: 'Level', accessor: 'ROOM_LEVEL', type: 'select', row: 0, col: 3,
		options: { value: 'ROOM_LEVEL', label: 'ROOM_LEVEL', entity: 'ROOM_INFO', order: 'ROOM_LEVEL',
			search: { Attribute: 'ROOM_LEVEL', Operator: 'is not null' }}},
	{ Header: 'Building', accessor: 'BUILDING_ID', type: 'select', row: 1, col: 0,
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDING', order: 'BUILDING_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'CHEMISTRY_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' }]}}},
	{ Header: 'Department', accessor: 'DEPARTMENT_ID', type: 'select', row: 1, col: 1,
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: 'DEPT_NAME' }},
	{ Header: 'Function', accessor: 'ROOM_FUNCTION', type: 'select', row: 1, col: 2,
		options: { value: 'FUNCTION_NAME', label: 'FUNCTION_NAME', entity: 'ROOM_FUNCTION', order: 'FUNCTION_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Square Footage (+/- 5)', accessor: 'SQUARE_FOOTAGE', row: 1, col: 3 },
	{ Header: 'Contact', accessor: 'ROLE_ID', type: 'select', row: 2, col: 0,
		options: { value: 'ROLE_ID', label: 'PERSON_NAME', entity: 'ROOM_CONTACT_SEARCH_VW', order: 'PERSON_NAME' }},
	{ Header: 'Unit', accessor: 'UNIT_ID', type: 'select', row: 2, col: 1,
		options: { value: 'UNIT_ID', label: 'UNIT_NAME', entity: 'ORG_UNIT', order: 'UNIT_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Phone', accessor: 'ROOM_PHONE', row: 2, col: 2 }
];

const roomList = {
	query: { entity: 'ROOM_SEARCH_VW', search: null, order: ['BUILDING_NAME', 'ROOM_LEVEL', 'ROOM_NAME'] },
	pageSize: 10,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addRoom()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editRoom(props.row.values.ROOM_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'ROOM_ID' },
		{ Header: 'Level', accessor: 'ROOM_LEVEL' },
		{ Header: 'Number', accessor: 'ROOM_NUMBER' },
		{ Header: 'Building', accessor: 'BUILDING_NAME' },
		{ Header: 'Name', accessor: 'ROOM_NAME' },
		{ Header: 'Phone', accessor: 'ROOM_PHONE' },
		{ Header: 'Unit', accessor: 'UNIT_NAME' },
		{ Header: 'Sq Foot', accessor: 'SQUARE_FOOTAGE' },
		{ Header: 'Contact', accessor: 'CONTACT_PHONE' }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'ROOM_ID', width: 10 },
	{ Header: 'Level', accessor: 'ROOM_LEVEL', width: 10 },
	{ Header: 'Number', accessor: 'ROOM_NUMBER', width: 10 },
	{ Header: 'Building', accessor: 'BUILDING_NAME', width: 20 },
	{ Header: 'Name', accessor: 'ROOM_NAME', width: 10 },
	{ Header: 'Phone', accessor: 'ROOM_PHONE', width: 10 },
	{ Header: 'Unit', accessor: 'UNIT_NAME', width: 30 },
	{ Header: 'Sq Foot', accessor: 'SQUARE_FOOTAGE', width: 10 },
	{ Header: 'Contact', accessor: 'CONTACT_PHONE', width: 20 }
];

export default withRouter(Rooms);