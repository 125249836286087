import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { LockFill, UnlockFill, Upc } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class DemCylinder extends ChemComponent {
	constructor(props) {
		super(props);
		
		var cylinderList;
		if (props.params.cylinderId) {
			cylinderList = this.copyTable(cylinderHistory);
			cylinderList.query.search = { Attribute: 'CYL_ID', Operator: '=', LongValue: props.params.cylinderId };
		} else {
			cylinderList = this.copyTable(cylindersReceived);
			cylinderList.query.search = { Attribute: 'TAG_NUM_IN', Operator: '=', StringValue: '' };
		}
		
		this.state = {
			editable: props.editable,
			cylinder: {
				CYL_ID: '' 
			},
			cylinderList: cylinderList,
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadCylinder();
	}

	loadCylinder(event) {
		var self = this;
		
		if (this.props.params.cylinderId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/GetDemCylinder',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					cylinderid: this.props.params.cylinderId
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var cylinder = data.Data[0];
						self.mvc2js(cylinder, cylinderColumns);
							
						self.mergeState({
							cylinder: cylinder,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Cylinder Error', data.Data.length + ' Cylinders found for ID ' + self.props.params.cylinderId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				cylinder: {
					CYL_ID: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var cylinder = this.copyObject(this.state.cylinder);
		var cylinderList = this.copyTable(this.state.cylinderList);
		this.setByAccessor(cylinder, accessor, value);
		// if this is a new cylinder and we are updating tag_num_in
		if (!this.props.params.cylinderId && accessor === 'TAG_NUM_IN') {
			// update received cylinder list parameter
			cylinderList.query.search.StringValue = value;
		}
		this.mergeState({ cylinder: cylinder, cylinderList: cylinderList, renderKey: this.state.renderKey + 1 });
	}
	
	onRetire(event) {
		var self = this;		
		
		this.showOKCancel('Confirm Retire Cylinder', 'Would you like to retire cylinder ' + this.props.params.cylinderId + '?', (retireCylinder) => {
			if (retireCylinder) {
				// make copy of data
				var cylinder = self.copyObject(self.state.cylinder);
				self.js2mvc(cylinder, cylinderColumns);
				cylinder.RETIRE_FLAG = 'Y';
				
				self.ajax({
					type: 'POST',
					url: self.getConfig().host + '/HeLAD/UpdateDemCylinder',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, cylinder: cylinder }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Cylinder <Link to={'/HeLAD/DemCylinders/' + self.props.params.cylinderId}>{self.props.params.cylinderId}</Link> retired.
							</>);
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var validation = this.validate(this.props.params.cylinderId ? cylinderColumns : newCylinderColumns, this.state.cylinder);
		if (!this.isEmpty(validation)) {
			this.showAlert("Required Fields", validation);
		} else {
			var self = this;
			
			// make copy of data
			var cylinder = this.copyObject(this.state.cylinder);
			this.js2mvc(cylinder, this.props.params.cylinderId ? cylinderColumns : newCylinderColumns);
			
			// if this is a new cylinder
			if (!this.props.params.cylinderId) {
				// set ID to sequence name
				cylinder.CYL_ID = 'dem_cyls_seq.nextval';
			}
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/HeLAD/UpdateDemCylinder',
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, cylinder: cylinder }
			}).done(function (data) {
				if (data.Success) {
					var cylinderId = self.props.params.cylinderId || data.ID;
					var verb = self.props.params.cylinderId ? 'updated' : 'inserted';
					self.props.parent.showConfirmation(() => {
						return (<>
							Cylinder <Link to={'/HeLAD/DemCylinders/' + cylinderId}>{cylinderId}</Link> {verb}.
						</>);
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}

	renderBarcodeForm(cylinderId) {
		
		var barcodeData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			cylinderid: cylinderId
		});
		
		return (
			<form action={this.getConfig().host + '/HeLAD/DemCylinderBarcode'} method='POST'>
				{barcodeData.map((pdfFormField, index) => {
					return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
				})}
				<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Print Barcode</Popover.Header></Popover>}>
					<input type='image' src={this.getConfig().host + '/Content/Icons/upc.png'} alt="Print Barcode" />
				</OverlayTrigger>
			</form>
		);
	}

	render() {
		
		var barcodeData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			cylinderid: this.props.params.cylinderId
		});

		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', marginBottom: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col xs={6}>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Cylinder {this.state.cylinder.CYL_ID} {this.state.cylinder.RETIRE_FLAG && '(RETIRED)'}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					  {this.props.params.cylinderId && <Col xs={6}>
						<form action={this.getConfig().host + '/HeLAD/DemCylinderBarcode'} method='POST'>
							{barcodeData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<Container fluid>
							  <Row>
							    <Col><div className='float-end'>Custom Title</div></Col>
								<Col style={{ marginTop: '-4px' }}><Form.Control type="text" name='title' value={this.state.cylinder.CUSTOM_TITLE} 
									onChange={(event) => this.onChange('CUSTOM_TITLE', event.target.value)} /></Col>
								<Col><OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Print Barcode</Popover.Header></Popover>}>
									<input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="PrintBarcode" /></OverlayTrigger></Col>
							  </Row>
							</Container>
						</form>					  
					  </Col>}
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={this.props.params.cylinderId ? cylinderColumns : newCylinderColumns} data={this.state.cylinder} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable}>
						  <Row>
						    <Col>
								{this.props.params.cylinderId ?
									<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Transaction History</div>:
									<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Cylinders already received under this tag number</div>
								}
							</Col>
							<Col>
								{this.state.editable && <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>}
								{this.state.editable && !this.state.cylinder.RETIRE_FLAG && <Button id="retire" variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }} onClick={(event) => this.onRetire(event)}>Retire</Button>}
							</Col>
						  </Row>
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_DemCylinderList' table={this.state.cylinderList} offsetHeight={this.props.params.cylinderId ? '375px' : '445px'} />
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const cylinderColumns = [
	{ Header: 'Last Transaction', accessor: 'LAST_TRANS_ID', row: 0, col: 0, editable: false },
	{ Header: 'Manufacturer Code', accessor: 'MFR_CODE', row: 0, col: 1, required: true },
	{ Header: 'Rental Rate', accessor: 'RENTAL_RATE_ID', type: 'select', row: 1, col: 0, required: true,
		options: { value: 'RENTAL_RATE_ID', label: 'RENTAL_RATE_DESC', entity: 'DEMURRAGE.RENTAL_RATE_VW' }},
	{ Header: 'Gas', accessor: 'GAS_ID', type: 'select', row: 1, col: 1, required: true,
		options: { value: 'GAS_ID', label: 'GAS_DESC', entity: 'DEMURRAGE.GAS_SELECT_VW' }},	
	{ Header: "This Cylinder's Cost $", accessor: 'GAS_COST', row: 2, col: 0, required: true },
	{ Header: 'Gas Default Price is $', accessor: 'GAS_DEFAULT_PRICE', row: 2, col: 1, editable: false },
	{ Header: 'Size', accessor: 'CYL_SIZE', row: 3, col: 0, editable: false },
	{ Header: 'Vendor', accessor: 'OWNER_ID', type: 'select', row: 3, col: 1, required: true,
		options: { value: 'OWNER_ID', label: 'OWNER_NAME', entity: 'DEMURRAGE.DEM_OWNERS', order: 'OWNER_NAME' }},
	{ Header: 'Entered', accessor: 'ENTERED', row: 4, col: 0, editable: false },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 4, col: 1, editable: false }
];

const newCylinderColumns = [
	{ Header: 'Customer Number', accessor: 'REC_NUM_IN', row: 0, col: 0, required: true },
	{ Header: 'Tag Number', accessor: 'TAG_NUM_IN', row: 0, col: 1, required: true },
	{ Header: 'IOC Number', accessor: 'IOC_NUM_IN', row: 1, col: 0, required: true },
	{ Header: 'Date Received', accessor: 'DATE_IN_COLLEGE', type: 'date', row: 1, col: 1, required: true },
	{ Header: 'Vendor', accessor: 'OWNER_ID', type: 'select', row: 2, col: 0, required: true,
		options: { value: 'OWNER_ID', label: 'OWNER_NAME', entity: 'DEMURRAGE.DEM_OWNERS', order: 'OWNER_NAME' }},
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 2, col: 1 },
	{ Header: 'Cylinder ID', accessor: 'MFR_CODE', row: 3, col: 0, required: true },
	{ Header: 'Rental Rate', accessor: 'RENTAL_RATE_ID', type: 'select', row: 3, col: 1, required: true,
		options: { value: 'RENTAL_RATE_ID', label: 'RENTAL_RATE_DESC', entity: 'DEMURRAGE.RENTAL_RATE_VW' }},
	{ Header: 'Gas', accessor: 'GAS_ID', type: 'select', row: 4, col: 0, required: true,
		options: { value: 'GAS_ID', label: 'GAS_DESC', entity: 'DEMURRAGE.GAS_SELECT_VW' }},	
	{ Header: 'Cost $', accessor: 'GAS_COST', row: 4, col: 1, subscript: 'Leave blank if default price.' },
];

const cylinderHistory = {
	query: { entity: 'DEMURRAGE.CYL_TRANS_HISTORY_VW', search: null },
	pageSize: 20,
	columns: [		
		{ Header: 'Gas', accessor: 'GAS_NAME' },
		{ Header: 'Owner', accessor: 'OWNER_NAME' },
		{ Header: 'SpeedType', accessor: 'COST_CENTER' },
		{ Header: '$ Gas', accessor: 'LINE_TOTAL' },
		{ Header: 'Customer Num.', accessor: 'REC_NUM_IN' },
		{ Header: 'IOC Num', accessor: 'IOC_NUM_IN' },
		{ Header: 'Tag Num IN', accessor: 'TAG_NUM_IN' },
		{ Header: 'Tag Num OUT', accessor: 'TAG_NUM_OUT' },
		{ Header: 'Cycle ID', accessor: 'CYCLE_ID' },
		{ Header: 'Received Date', accessor: 'DATE_IN_COLLEGE' },
		{ Header: 'Checkout User', accessor: 'OUT_USER_NAME' },
		{ Header: 'Checkout Date', accessor: 'OUT_DATE' },
		{ Header: 'Rental Rate', accessor: 'RENTAL_RATE' },
		{ Header: 'Return User', accessor: 'RETURN_USER_NAME' },
		{ Header: 'Return Date', accessor: 'RETURN_DATE' },
		{ Header: 'Return Manifest', accessor: 'DATE_OUT_COLLEGE' }
	]
};

const cylindersReceived = {
	query: { entity: 'DEMURRAGE.CYL_RECEIVED_VW', search: null },
	pageSize: 20,
	columns: [
		{ Header: 'Cylinder ID', accessor: 'CYL_ID' },
		{ Header: props => <Upc />, accessor: 'drag', width: 25,
			Cell: props => props.parent.renderBarcodeForm(props.row.values.CYL_ID) },
		{ Header: 'Rental Type', accessor: 'RENTAL_DESC', },
		{ Header: 'Gas', accessor: 'GAS_NAME' },
		{ Header: 'Cost', accessor: 'GAS_PRICE' }
	]
};

export default withRouter(DemCylinder);
