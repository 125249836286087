import React from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withBottleDefaultRoute } from './withBottleDefaultRoute';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import Tabs from '../Tabs';

class Bottle extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			activeTab: props.params.activeTab || 'Bottle',
			inventory: {
				ITEM_ID: null
			},
			chem_checkout: [],
			chemicalColumns: this.copyColumns(chemicalColumns),
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadBottle();
	}

	componentDidUpdate(prevProps) {
		var newActiveTab = this.props.params.activeTab || 'Chemical';
		
		// this is necessary so browser back/forward buttons will work across tabs
		if (newActiveTab !== this.state.activeTab) {
			this.mergeState({
				activeTab: newActiveTab,
				renderKey: this.state.renderKey + 1
			});
		}
		// also reload the item if the item ID has changed
		if (this.props.params.itemId !== prevProps.params.itemId) {
			this.loadBottle();
		}
	}

	loadBottle(event) {
		var self = this;

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Reuse/GetBottle',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				itemid: this.props.params.itemId
			}
		}).done(function (data) {
			if (data.Success) {
				var inventory = data.Data.inventory;
				var newChemColumns = self.copyColumns(chemicalColumns);
				
				if (inventory.STORAGE_STATUS !== 'In ReUse') {
					// remove In ReUse fields
					newChemColumns = self.removeColumnsByAccessor(newChemColumns, ['SHELF_CODE','STORAGE_NAME','ROOM_NAME']);
				}
				if (inventory.STORAGE_STATUS !== 'Checked Out') {
					// remove Checked Out fields
					newChemColumns = self.removeColumnsByAccessor(newChemColumns, ['RES_GROUP','OUT_USER_CONTACT','OUT_USER_LOCATION']);
				}
				if (!['In ReUse','Checked Out'].includes(inventory.STORAGE_STATUS)) {
					// remove In Reuse and Checked Out fields as well as date Shelved
					newChemColumns = self.removeColumnsByAccessor(newChemColumns, 
						['SHELF_CODE','STORAGE_NAME','ROOM_NAME','RES_GROUP','OUT_USER_CONTACT','OUT_USER_LOCATION','DATE_SHELVED']);
				}

				// convert from mvc to js
				self.mvc2js(inventory, newChemColumns);
					
				self.mergeState({
					inventory: inventory,
					chem_checkout: data.Data.chem_checkout,
					chemicalColumns: newChemColumns,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	onRemove(event) {
		this.props.removeFromCart(this.state.inventory.ITEM_ID);
	}
		
	onSubmit(event) {
		this.props.addToCart(this.state.inventory);
	}
	
	onClickTabItem(tab) {
		this.props.navigate('/Reuse/Bottles/' + this.props.params.itemId + '/' + tab);
	}

	canClickTabItem(tab) {
		return true;
	}
	
	renderFormulaCell(props) {
		return (
			<Form.Group as={Row} controlId={props.column.accessor} style={{ height: '100%' }}>
				<Form.Label column sm={props.labelColWidth} style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{__html: props.column.Header}}></Form.Label>
				<Col sm={props.valueColWidth} style={{ flex: 1, display: 'flex', alignItems: 'center', marginBottom: '14px', background: '#e9ecef', borderRadius: 5 }}>
					<div>{this.renderFormulae(props.value)}</div>
				</Col>
			</Form.Group>
		);
	}

	renderFormula(formula) {
		var self = this;
		if (this.isEmpty(formula)) return null;
		var elements = formula.match(/([A-Z][a-z]?)([0-9]*)/g);
		if (this.isEmpty(elements) || elements.length === 0) return null;
		return elements.map((element, key) => {
			var elementAndNumber = element.match(/^([A-Z][a-z]?)([0-9]*)$/);
			if (self.isEmpty(elementAndNumber[2])) {
				return (<React.Fragment key={key}>{elementAndNumber[1]}</React.Fragment>);
			} else {
				return (<React.Fragment key={key}>{elementAndNumber[1]}<sub>{elementAndNumber[2]}</sub></React.Fragment>);
			}
		});
	}
	
	renderFormulae(formulae) {
		if (this.isEmpty(formulae)) return '';
		var pmatch = formulae.match(/^\(((?:[a-zA-Z]+[0-9]*)+)\)n(?:\s●\s([a-zA-Z0-9]+)\s((?:[a-zA-Z]+[0-9]*)+))?(?:\s●\s([a-zA-Z0-9]+)\s((?:[a-zA-Z]+[0-9]*)+))?$/);
		var match = formulae.match(/^((?:[a-zA-Z]+[0-9]*)+)(?:\s●\s([a-zA-Z0-9]+)\s((?:[a-zA-Z]+[0-9]*)+))?(?:\s●\s([a-zA-Z0-9]+)\s((?:[a-zA-Z]+[0-9]*)+))?$/);
		if (pmatch) {
			return (<>({this.renderFormula(pmatch[1])})n{!this.isEmpty(pmatch[2]) && <> ● {pmatch[2]} {this.renderFormula(pmatch[3])}</>}{!this.isEmpty(pmatch[4]) && <> ● {pmatch[4]} {this.renderFormula(pmatch[5])}</>}</>);
		} else if (match) {
			return (<>{this.renderFormula(match[1])}{!this.isEmpty(match[2]) && <> ● {match[2]} {this.renderFormula(match[3])}</>}{!this.isEmpty(match[4]) && <> ● {match[4]} {this.renderFormula(match[5])}</>}</>);
		} else {
			return (<>{formulae}</>);
		}
	}
	
	renderChemicalName() {
		
		var safetyData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			chemid: this.state.inventory.CHEM_ID
		});
		
		var optionalMargin = {};
		if (!this.state.editable) optionalMargin.marginBottom = '10px';

		return(
		  <Container fluid>
		    <Row>
			  <Col xs={5} style={optionalMargin}>
				  <form action={this.getConfig().host + '/Reuse/SafetyPage'} method='POST' target='_blank' className="float-end">
					{safetyData.map((safetyFormField, index) => {
					  return (<input key={index} type='hidden' name={safetyFormField.name} value={safetyFormField.value} />);
					})}
					<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Safety Sheet</Popover.Header></Popover>}>
					  <input type='image' src={this.getConfig().host + '/Content/Icons/safety_page.gif'} alt="Safety Sheet" />
					</OverlayTrigger>
				  </form>
			  </Col>
			  <Col xs={7} style={{ alignSelf: 'center' }}>
			    {this.state.inventory.CHEM_NAME}
			  </Col>
			</Row>
		  </Container>
		);
	}

	render() {
		/* compose price comparison */
		var priceComparison = this.isEmpty(this.state.inventory.PRICE) ? '' : 
			('Compared to $' + this.formatCurrency(this.state.inventory.PRICE) +
			' for a ' + this.state.inventory.CHEM_AMOUNT + ' ' + this.state.inventory.UNIT_NAME + 
			' bottle as listed by ' + this.state.inventory.CATALOG_NAME + ', ' + this.state.inventory.CATALOG_YEAR);
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Inventory Item {this.state.inventory.ITEM_ID}</div>
						  {!this.isEmpty(this.state.inventory.PRICE) &&
							<div style={{ color: 'red', fontWeight: 'bold', marginBottom: '10px' }}>{priceComparison}</div>
						  }
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingBottom: '10px' }}>
					    <Tabs activeTab={this.state.activeTab} canClickTabItem={(tab) => this.canClickTabItem(tab)} onClickTabItem={(tab) => this.onClickTabItem(tab)}>
						  <div label='Bottle'>
							<ChemEdit parent={this} columns={this.state.chemicalColumns} data={this.state.inventory} user={this.props.user} renderKey={this.state.renderKey} editable={false} />
							<ChemEdit parent={this} columns={noteColumns} data={this.state.inventory} user={this.props.user} renderKey={this.state.renderKey} editable={false} labelColWidth={2} valueColWidth={10} />
						  </div>
						  <div label='History'>
							<ChemTable renderKey={this.state.renderKey} parent={this} name='Reuse_Bottle_History' columns={historyColumns} data={this.state.chem_checkout}
								offsetHeight='85px' />
						  </div>
						</Tabs>
					  </Col>
					</Row>
					<Form.Group as={Row}>
					  <Col>
					    {this.state.inventory.STORAGE_STATUS === 'In ReUse' && (this.props.itemInCart(this.state.inventory.ITEM_ID) ?
						  <Button id="remove" variant="warning" type="button" className="float-end" 
							onClick={(event) => this.onRemove(event)}>Remove from Cart</Button>:
						  <Button id="add" variant="warning" type="button" className="float-end"
							onClick={(event) => this.onSubmit(event)}>Add to Cart</Button>)
						}
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const chemicalColumns = [
	{ Header: 'Chem ID', accessor: 'CHEM_ID', row: 0, col: 0 },
	{ Header: 'Name', accessor: 'CHEM_NAME', row: 0, col: 1 },
	{ Header: 'CAS Number', accessor: 'CAS_NUMBER', row: 0, col: 2 },
	{ Header: 'Formula', accessor: 'FORMULA', row: 0, col: 3,
	  Editor: props => props.parent.renderFormulaCell(props) },
	{ Header: 'Bottle ID', accessor: 'BOTTLE_ID', row: 1, col: 0 },
	{ Header: 'Bottle Type', accessor: 'CONTAINER_NAME', row: 1, col: 1 },
	{ Header: 'Bottle Size', accessor: 'BOTTLE_SIZE', row: 1, col: 2 },
	{ Header: 'Amount in Bottle', accessor: 'AMOUNT', row: 1, col: 3 },
	{ Header: 'Date on Bottle', accessor: 'DATE_ON_BOTTLE', type: 'date', row: 2, col: 0 },
	{ Header: 'Brand', accessor: 'BRAND_NAME', row: 2, col: 1 },
	{ Header: 'Catalog #', accessor: 'CATALOG_NUMBER', row: 2, col: 2 },
	{ Header: 'Lot #', accessor: 'LOT_NUM', row: 2, col: 3 },
	{ Header: 'Form', accessor: 'FORM_NAME', row: 3, col: 0 },
	{ Header: 'Purity', accessor: 'PURITY', row: 3, col: 1 },
	{ Header: 'Grade', accessor: 'ITEM_GRADES', row: 3, col: 2 },
	{ Header: 'Status', accessor: 'STORAGE_STATUS', row: 3, col: 3 },
	/* these fields only show if storage status is 1 ('In ReUse') */
	{ Header: 'Shelf Code', accessor: 'SHELF_CODE', row: 4, col: 0 },
	{ Header: 'Cab/Frig #', accessor: 'STORAGE_NAME', row: 4, col: 1 },
	{ Header: 'Room/Bldg', accessor: 'ROOM_NAME', row: 4, col: 2 },
	/* these fields only show if storage status is 2 ('Checked Out') */
	{ Header: 'PI Group', accessor: 'RES_GROUP', row: 4, col: 0 },
	{ Header: 'Contact', accessor: 'OUT_USER_CONTACT', row: 4, col: 1},
	{ Header: 'Room/Bldg', accessor: 'OUT_USER_LOCATION', row: 4, col: 2 },
	/* this field only shows if storage status is 1 or 2 */
	{ Header: 'Date Shelved', accessor: 'DATE_SHELVED', type: 'date', row: 4, col: 3 }
];

const noteColumns = [
	{ Header: 'Notes', accessor: 'NOTES_1' },
	{ Header: 'Other Notes', accessor: 'NOTES_2' }
];

const historyColumns = [
	{ Header: 'Checkout ID', accessor: 'CHECKOUT_ID' },
	{ Header: 'Date Out', accessor: 'DATE_OUT' },
	{ Header: 'Name', accessor: 'PERSON_NAME' },
	{ Header: 'Location', accessor: 'LOCATION' },
	{ Header: 'Phone', accessor: 'PHONE1' },
	{ Header: 'PI', accessor: 'PI_NAME' },
	{ Header: 'Returned', accessor: 'DATE_RETURNED' }
];

export default withBottleDefaultRoute(Bottle);
