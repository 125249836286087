import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import Tabs from '../Tabs';
import { Link } from 'react-router-dom';
import { PencilSquare } from 'react-bootstrap-icons';

class Invalid extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var speedtypeSearch = {
			SERVICE_NAME: '',
			TRANS_ID: '',
			TRANS_DATE: null,
			COST_CENTER: '',
			BFS_FUND: '',
			USER_GROUP: '',
			LABORHRS: '',
			MATERIAL: '',
			DETAILS: ''
		};
		
		// initialize speedtype list
		var initialSpeedtypeList = this.copyTable(speedtypeList);
		initialSpeedtypeList.query.search = this.constructSpeedtypeSearch(speedtypeSearch);
		
		// remove add/edit column if not authorized
		if (props.user.crs.userAccessID !== 1) {
			initialSpeedtypeList.columns.splice(0, 1);
		}

		var rechargeSearch = {
			SERVICE_NAME: '',
			TRANS_ID: '',
			TRANS_DATE: null,
			COST_CENTER: '',
			BFS_FUND: '', // placeholder, this grid doesn't have bfs_fund
			USER_GROUP: '',
			LABORHRS: '',
			MATERIAL: '',
			DETAILS: ''
		};
		
		// initialize recharge list
		var initialRechargeList = this.copyTable(rechargeList);
		initialRechargeList.query.search = this.constructRechargeSearch(rechargeSearch);

		var unbilledSearch = {
			SERVICE_NAME: '',
			TRANS_ID: '',
			TRANS_DATE: null,
			COST_CENTER: '',
			USER_GROUP: '',
			LABORHRS: '',
			MATERIAL: '',
			DETAILS: '',
			COMMENTS: ''
		};
		
		// initialize unbilled list
		var initialUnbilledList = this.copyTable(unbilledList);
		initialUnbilledList.query.search = this.constructUnbilledSearch(unbilledSearch);

		this.state = {
			speedtypeList: initialSpeedtypeList,
			speedtypeSearch: speedtypeSearch,
			speedtypeSearchCount: null,
			rechargeList: initialRechargeList,
			rechargeSearch: rechargeSearch,
			rechargeSearchCount: null,
			unbilledList: initialUnbilledList,
			unbilledSearch: unbilledSearch,
			unbilledSearchCount: null,
			activeTab: 'Invalid Speedtypes',
			cycleEnd: null,
			renderKey: 1
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'CURRENT_CYCLE_VW',
				search: null,
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length > 0 && !self.isEmpty(data.Data[0].END_DATE)) {
					var cycleEnd = self.getDate(data.Data[0].END_DATE);
					self.mergeState({
						cycleEnd: cycleEnd.toLocaleDateString('en-US', { year: 'numeric', month: 'long' }),
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Current Cycle Error', 'Current cycle end date not found.');
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	onChangeSpeedtypeSearch(accessor, value) {
		var speedtypeSearch = this.copyObject(this.state.speedtypeSearch);
		this.setByAccessor(speedtypeSearch, accessor, value);
		this.mergeState({ speedtypeSearch: speedtypeSearch });
	}
	
	onSubmitSpeedtypeSearch(event) {
		var newSpeedtypeList = this.copyTable(speedtypeList);
		newSpeedtypeList.query.search = this.constructSpeedtypeSearch(this.state.speedtypeSearch);
		this.mergeState({ 
			speedtypeList: newSpeedtypeList,
			speedtypeSearchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSpeedtypeSearchResult(data) {
		this.mergeState({
			speedtypeSearchCount: data.ResultCount
		});
	}
	
	constructSpeedtypeSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.SERVICE_NAME)) search.Children.push({ Attribute: 'SERVICE_NAME', Operator: 'like', StringValue: '%' + data.SERVICE_NAME + '%' });
		if (!this.isEmpty(data.TRANS_ID)) search.Children.push({ Attribute: 'TRANS_ID', Operator: '=', LongValue: data.TRANS_ID });		
		if (!this.isEmpty(data.TRANS_DATE)) search.Children.push({ Attribute: 'TRANS_DATE', Operator: '=', StringValue: this.dateToString(this.getDate(data.TRANS_DATE)) });
		if (!this.isEmpty(data.COST_CENTER)) search.Children.push({ Attribute: 'COST_CENTER', Operator: 'like', StringValue: '%' + data.COST_CENTER + '%' });
		if (!this.isEmpty(data.BFS_FUND)) search.Children.push({ Attribute: 'BFS_FUND', Operator: '=', StringValue: data.BFS_FUND });
		if (!this.isEmpty(data.USER_GROUP)) search.Children.push({ Operator: 'or', Children: [
			{ Attribute: 'USER_GROUP', Operator: 'like', StringValue: '%' + data.USER_GROUP + '%' },
			{ Attribute: 'S_PI_NAME', Operator: 'like', StringValue: '%' + data.USER_GROUP + '%' }
		]});
		if (!this.isEmpty(data.LABORHRS)) search.Children.push({ Attribute: 'LABORHRS', Operator: '=', DecimalValue: data.LABORHRS });		
		if (!this.isEmpty(data.MATERIAL)) search.Children.push({ Attribute: 'MATERIAL', Operator: '=', DecimalValue: data.MATERIAL });		
		if (!this.isEmpty(data.DETAILS)) search.Children.push({ Attribute: 'DETAILS', Operator: 'like', StringValue: '%' + data.DETAILS + '%' });
		
		// if nothing was specified, forget it
		if (search.Children.length === 0) return null;
		return search;
	}
	
	correctLowercaseSpeedtypes(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/CorrectLowercaseSpeedtypes',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				search: this.constructSpeedtypeSearch(this.state.speedtypeSearch)
			}
		}).done(function (data) {
			if (data.Success) {
				self.showAlert('Update Succeeded', data.Message, () => {
					self.onSubmitSpeedtypeSearch();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	onChangeRechargeSearch(accessor, value) {
		var rechargeSearch = this.copyObject(this.state.rechargeSearch);
		this.setByAccessor(rechargeSearch, accessor, value);
		this.mergeState({ rechargeSearch: rechargeSearch });
	}
	
	onSubmitRechargeSearch(event) {
		var newRechargeList = this.copyTable(rechargeList);
		newRechargeList.query.search = this.constructRechargeSearch(this.state.rechargeSearch);
		this.mergeState({ 
			rechargeList: newRechargeList,
			rechargeSearchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onRechargeSearchResult(data) {
		this.mergeState({
			rechargeSearchCount: data.ResultCount
		});
	}
	
	constructRechargeSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.SERVICE_NAME)) search.Children.push({ Attribute: 'SERVICE_NAME', Operator: 'like', StringValue: '%' + data.SERVICE_NAME + '%' });
		if (!this.isEmpty(data.TRANS_ID)) search.Children.push({ Attribute: 'TRANS_ID', Operator: '=', LongValue: data.TRANS_ID });		
		if (!this.isEmpty(data.TRANS_DATE)) search.Children.push({ Attribute: 'TRANS_DATE', Operator: '=', StringValue: this.dateToString(this.getDate(data.TRANS_DATE)) });
		if (!this.isEmpty(data.COST_CENTER)) search.Children.push({ Attribute: 'COST_CENTER', Operator: 'like', StringValue: '%' + data.COST_CENTER + '%' });
		if (!this.isEmpty(data.USER_GROUP)) search.Children.push({ Operator: 'or', Children: [
			{ Attribute: 'USER_GROUP', Operator: 'like', StringValue: '%' + data.USER_GROUP + '%' },
			{ Attribute: 'S_PI_NAME', Operator: 'like', StringValue: '%' + data.USER_GROUP + '%' }
		]});
		if (!this.isEmpty(data.LABORHRS)) search.Children.push({ Attribute: 'LABORHRS', Operator: '=', DecimalValue: data.LABORHRS });		
		if (!this.isEmpty(data.MATERIAL)) search.Children.push({ Attribute: 'MATERIAL', Operator: '=', DecimalValue: data.MATERIAL });		
		if (!this.isEmpty(data.DETAILS)) search.Children.push({ Attribute: 'DETAILS', Operator: 'like', StringValue: '%' + data.DETAILS + '%' });
		
		// if nothing was specified, forget it
		if (search.Children.length === 0) return null;
		return search;
	}
	
	onChangeUnbilledSearch(accessor, value) {
		var unbilledSearch = this.copyObject(this.state.unbilledSearch);
		this.setByAccessor(unbilledSearch, accessor, value);
		this.mergeState({ unbilledSearch: unbilledSearch });
	}
	
	onSubmitUnbilledSearch(event) {
		var newUnbilledList = this.copyTable(unbilledList);
		newUnbilledList.query.search = this.constructUnbilledSearch(this.state.unbilledSearch);
		this.mergeState({ 
			unbilledList: newUnbilledList,
			unbilledSearchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onUnbilledSearchResult(data) {
		this.mergeState({
			unbilledSearchCount: data.ResultCount
		});
	}
	
	constructUnbilledSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.SERVICE_NAME)) search.Children.push({ Attribute: 'SERVICE_NAME', Operator: 'like', StringValue: '%' + data.SERVICE_NAME + '%' });
		if (!this.isEmpty(data.TRANS_ID)) search.Children.push({ Attribute: 'TRANS_ID', Operator: '=', LongValue: data.TRANS_ID });		
		if (!this.isEmpty(data.TRANS_DATE)) search.Children.push({ Attribute: 'TRANS_DATE', Operator: '=', StringValue: this.dateToString(this.getDate(data.TRANS_DATE)) });
		if (!this.isEmpty(data.COST_CENTER)) search.Children.push({ Attribute: 'COST_CENTER', Operator: 'like', StringValue: '%' + data.COST_CENTER + '%' });
		if (!this.isEmpty(data.USER_GROUP)) search.Children.push({ Operator: 'or', Children: [
			{ Attribute: 'USER_GROUP', Operator: 'like', StringValue: '%' + data.USER_GROUP + '%' },
			{ Attribute: 'S_PI_NAME', Operator: 'like', StringValue: '%' + data.USER_GROUP + '%' }
		]});
		if (!this.isEmpty(data.LABORHRS)) search.Children.push({ Attribute: 'LABORHRS', Operator: '=', DecimalValue: data.LABORHRS });		
		if (!this.isEmpty(data.MATERIAL)) search.Children.push({ Attribute: 'MATERIAL', Operator: '=', DecimalValue: data.MATERIAL });		
		if (!this.isEmpty(data.DETAILS)) search.Children.push({ Attribute: 'DETAILS', Operator: 'like', StringValue: '%' + data.DETAILS + '%' });
		if (!this.isEmpty(data.COMMENTS)) search.Children.push({ Attribute: 'COMMENTS', Operator: 'like', StringValue: '%' + data.COMMENTS + '%' });
		
		// if nothing was specified, forget it
		if (search.Children.length === 0) return null;
		return search;
	}

	editTransaction(serviceId, jobId) {
		this.props.navigate('/CRS/Transaction/' + serviceId + '/' + jobId);
	}

	onClickTabItem(tab) {
		this.mergeState({ activeTab: tab });
	}
	
	render() {

		var exportSpeedtypeData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Invalid Speedtypes',
			columns: speedtypeExportColumns,
			entity: 'RECHARGES.INVALID_SPEEDTYPES_VW',
			search: this.constructSpeedtypeSearch(this.state.speedtypeSearch),
			order: 'SERVICE_ID'
		});			

		var exportRechargeData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Invalid Recharges',
			columns: rechargeExportColumns,
			entity: 'RECHARGES.INVALID_RECHARGES_VW',
			search: this.constructRechargeSearch(this.state.rechargeSearch),
			order: 'SERVICE_NAME'
		});			

		var exportUnbilledData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Unbilled Recharges',
			columns: unbilledExportColumns,
			entity: 'RECHARGES.UNBILLED_RECHARGES_VW',
			search: this.constructUnbilledSearch(this.state.unbilledSearch)
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <Tabs onClickTabItem={(tab) => this.onClickTabItem(tab)}>
				      <div label='Invalid Speedtypes'>
					    <div style={{ marginBottom: '10px' }}>Invalid speedtype list for the current billing recharge cycle of {this.state.cycleEnd}</div>
						<ChemEdit parent={this} columns={speedtypeSearch} data={this.state.speedtypeSearch} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeSpeedtypeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSpeedtypeSearch(event)} {...this.props}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							<Col xs={4} style={{ marginTop: 8}}>
								{this.isEmpty(this.state.speedtypeSearchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.speedtypeSearchCount} records found.
							</Col>
							<Col xs={1} style={{ marginTop: 6}}>
								<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
									{exportSpeedtypeData.map((excelFormField, index) => {
										return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={1} style={{ marginTop: 6}}>
								<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
									{exportSpeedtypeData.map((pdfFormField, index) => {
										return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={6}>
							  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
							  {this.props.user.crs.userAccessID === 1 && <Button id="correct" variant="warning" type="button" className="float-end" style={{ marginRight: '8px' }} onClick={(event) => this.correctLowercaseSpeedtypes(event)}>Correct Lowercase Speedtypes</Button>}
							</Col>
						  </Form.Group>
						</ChemEdit>
					  </div>
					  <div label='Invalid Recharges'>
						{this.props.user.crs.userAccessID === 1 ? <>
					      <div style={{ marginBottom: '10px' }}>Invalid recharge list for the current billing recharge cycle of {this.state.cycleEnd} due to incorrect service ID or invalid rate ID</div>
						  <ChemEdit parent={this} columns={rechargeSearch} data={this.state.rechargeSearch} renderKey={this.state.renderKey} 
						    onChange={(accessor, value) => this.onChangeRechargeSearch(accessor, value)} onSubmit={(event) => this.onSubmitRechargeSearch(event)} {...this.props}>
						    <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							  <Col xs={4} style={{ marginTop: 8}}>
								  {this.isEmpty(this.state.rechargeSearchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.rechargeSearchCount} records found.
							  </Col>
							  <Col xs={1} style={{ marginTop: 6}}>
								  <form action={this.getConfig().host + '/Home/Excel'} method='POST'>
									  {exportRechargeData.map((excelFormField, index) => {
										  return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
									  })}
									  <OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
									    <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
									  </OverlayTrigger>
								  </form>
							  </Col>
							  <Col xs={1} style={{ marginTop: 6}}>
								  <form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
									  {exportRechargeData.map((pdfFormField, index) => {
										  return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
									  })}
									  <OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
									    <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
									  </OverlayTrigger>
								  </form>
							  </Col>
							  <Col xs={6}>
							    <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
							  </Col>
						    </Form.Group>
						  </ChemEdit></>:
						  <>User is not authorized to see Invalid Recharges.</>
						}
					  </div>
					  <div label='Unbilled Recharges'>
						{this.props.user.crs.userAccessID === 1 ? <>
					      <div style={{ marginBottom: '10px' }}>Recharges marked not ready to bill for the current billing recharge cycle of {this.state.cycleEnd} in recharge table (shops and research facilities)</div>
						  <ChemEdit parent={this} columns={unbilledSearch} data={this.state.unbilledSearch} renderKey={this.state.renderKey} 
						    onChange={(accessor, value) => this.onChangeUnbilledSearch(accessor, value)} onSubmit={(event) => this.onSubmitUnbilledSearch(event)} {...this.props}>
						    <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							  <Col xs={4} style={{ marginTop: 8}}>
								  {this.isEmpty(this.state.unbilledSearchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.unbilledSearchCount} records found.
							  </Col>
							  <Col xs={1} style={{ marginTop: 6}}>
								  <form action={this.getConfig().host + '/Home/Excel'} method='POST'>
									  {exportUnbilledData.map((excelFormField, index) => {
										  return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
									  })}
									  <OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
									    <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
									  </OverlayTrigger>
								  </form>
							  </Col>
							  <Col xs={1} style={{ marginTop: 6}}>
								  <form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
									  {exportUnbilledData.map((pdfFormField, index) => {
										  return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
									  })}
									  <OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
									    <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
									  </OverlayTrigger>
								  </form>
							  </Col>
							  <Col xs={6}>
							    <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
							  </Col>
						    </Form.Group>
						  </ChemEdit></>:
						  <>User is not authorized to see Unbilled Recharges.</>	
						}
					  </div>
					</Tabs>
				  </Col>
				</Row>
				<Row>
				  <Col>
				    {this.state.activeTab === 'Invalid Speedtypes' && <QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_InvalidSpeedtypes' table={this.state.speedtypeList}
					  onResult={(data) => this.onSpeedtypeSearchResult(data)} offsetHeight='345px' />}
					{this.state.activeTab === 'Invalid Recharges' && this.props.user.crs.userAccessID === 1 && <QueryTable renderKey={this.state.renderKey} parent={this} 
					  name='CRS_InvalidRecharges' table={this.state.rechargeList} onResult={(data) => this.onRechargeSearchResult(data)} offsetHeight='345px' />}
					{this.state.activeTab === 'Unbilled Recharges' && this.props.user.crs.userAccessID === 1 && <QueryTable renderKey={this.state.renderKey} parent={this} 
					  name='CRS_UnbilledRecharges' table={this.state.unbilledList} onResult={(data) => this.onUnbilledSearchResult(data)} offsetHeight='345px' />}
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const speedtypeSearch = [
	{ Header: 'Service', accessor: 'SERVICE_NAME', row: 0, col: 0 },
	{ Header: 'ID', accessor: 'TRANS_ID', row: 0, col: 1 },
	{ Header: 'Date', accessor: 'TRANS_DATE', type: 'date', row: 0, col: 2 },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', row: 1, col: 0 },
	{ Header: 'Fund', accessor: 'BFS_FUND', row: 1, col: 1 },
	{ Header: 'User Name/Group/PI', accessor: 'USER_GROUP', row: 1, col: 2 },
	{ Header: 'Labor Hrs', accessor: 'LABORHRS', row: 2, col: 0 },
	{ Header: '$ Material', accessor: 'MATERIAL', row: 2, col: 1 },
	{ Header: 'Details', accessor: 'DETAILS', row: 2, col: 2 }
];

const speedtypeList = {
	query: { entity: 'RECHARGES.INVALID_SPEEDTYPES_VW', search: null, order: 'SERVICE_ID' },
	pageSize: 20,
	columns: [
		{ Header: '', accessor: 'id', show: false },
		{ Header: '', accessor: 'SERVICE_ID', show: false },
		{ Header: 'Service', accessor: 'SERVICE_NAME', width: 250 },
		{ Header: 'ID', accessor: 'TRANS_ID', width: 75,
			Cell: props => <Link to={'/CRS/Jobs/' + props.row.values.SERVICE_ID + '/' + props.value}>{props.value}</Link> },
		{ Header: 'Date', accessor: 'TRANS_DATE', width: 100 },
		{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 125 },
		{ Header: 'Fund', accessor: 'BFS_FUND', width: 80 },
		{ Header: 'User Name/Group', accessor: 'USER_GROUP', width: 150 },
		{ Header: 'PI', accessor: 'S_PI_NAME', width: 150 },
		{ Header: 'Labor Hrs', accessor: 'LABORHRS', width: 100 },
		{ Header: '$ Material', accessor: 'MATERIAL', width: 100 },
		{ Header: 'Details', accessor: 'DETAILS', width: 250 }
	]
};

const speedtypeExportColumns = [
	{ Header: 'Service', accessor: 'SERVICE_NAME', width: 50 },
	{ Header: 'ID', accessor: 'TRANS_ID', width: 15 },
	{ Header: 'Date', accessor: 'TRANS_DATE', width: 20, format: 'mm/dd/yy' },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 25 },
	{ Header: 'Fund', accessor: 'BFS_FUND', width: 16 },
	{ Header: 'User Name/Group', accessor: 'USER_GROUP', width: 30 },
	{ Header: 'PI', accessor: 'S_PI_NAME', width: 30 },
	{ Header: 'Labor Hrs', accessor: 'LABORHRS', width: 20, format: '#,##0.00' },
	{ Header: '$ Material', accessor: 'MATERIAL', width: 20, format: '#,##0.00' },
	{ Header: 'Details', accessor: 'DETAILS', width: 50 }
];

const rechargeSearch = [
	{ Header: 'Service', accessor: 'SERVICE_NAME', row: 0, col: 0 },
	{ Header: 'ID', accessor: 'TRANS_ID', row: 0, col: 1 },
	{ Header: 'Date', accessor: 'TRANS_DATE', type: 'date', row: 0, col: 2 },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', row: 1, col: 0 },
	{ Header: '', accessor: 'BFS_FUND', row: 1, col: 1, editable: false }, // placeholder so fields will line up with speedtype fields
	{ Header: 'User Name/Group/PI', accessor: 'USER_GROUP', row: 1, col: 2 },
	{ Header: 'Labor Hrs', accessor: 'LABORHRS', row: 2, col: 0 },
	{ Header: '$ Material', accessor: 'MATERIAL', row: 2, col: 1 },
	{ Header: 'Details', accessor: 'DETAILS', row: 2, col: 2 }
];

const rechargeList = {
	query: { entity: 'RECHARGES.INVALID_RECHARGES_VW', search: null, order: 'SERVICE_NAME' },
	pageSize: 20,
	columns: [
		{ Header: 'Service', accessor: 'SERVICE_NAME', width: 250 },
		{ Header: 'ID', accessor: 'TRANS_ID', width: 75 },
		{ Header: 'Date', accessor: 'TRANS_DATE', width: 100, format: 'mm/dd/yy' },
		{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 125 },
		{ Header: 'User Name/Group', accessor: 'USER_GROUP', width: 150 },
		{ Header: 'PI', accessor: 'S_PI_NAME', width: 150 },
		{ Header: 'Labor Hrs', accessor: 'LABORHRS', width: 100, format: '#,##0.00' },
		{ Header: '$ Material', accessor: 'MATERIAL', width: 100, format: '#,##0.00' },
		{ Header: 'Details', accessor: 'DETAILS', width: 250 }
	]
};

const rechargeExportColumns = [
	{ Header: 'Service', accessor: 'SERVICE_NAME', width: 50 },
	{ Header: 'ID', accessor: 'TRANS_ID', width: 15 },
	{ Header: 'Date', accessor: 'TRANS_DATE', width: 20 },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 25 },
	{ Header: 'User Name/Group', accessor: 'USER_GROUP', width: 30 },
	{ Header: 'PI', accessor: 'S_PI_NAME', width: 30 },
	{ Header: 'Labor Hrs', accessor: 'LABORHRS', width: 20 },
	{ Header: '$ Material', accessor: 'MATERIAL', width: 20 },
	{ Header: 'Details', accessor: 'DETAILS', width: 50 }
];

const unbilledSearch = [
	{ Header: 'Service', accessor: 'SERVICE_NAME', row: 0, col: 0 },
	{ Header: 'ID', accessor: 'TRANS_ID', row: 0, col: 1 },
	{ Header: 'Date', accessor: 'TRANS_DATE', type: 'date', row: 0, col: 2 },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', row: 1, col: 0 },
	{ Header: 'Comments', accessor: 'COMMENTS', row: 1, col: 1 },
	{ Header: 'User Name/Group/PI', accessor: 'USER_GROUP', row: 1, col: 2 },
	{ Header: 'Labor Hrs', accessor: 'LABORHRS', row: 2, col: 0 },
	{ Header: '$ Material', accessor: 'MATERIAL', row: 2, col: 1 },
	{ Header: 'Details', accessor: 'DETAILS', row: 2, col: 2 }
];

const unbilledList = {
	query: { entity: 'RECHARGES.UNBILLED_RECHARGES_VW' },
	pageSize: 20,
	columns: [
		{ Header: '', accessor: 'SERVICE_ID', width: 25,
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editTransaction(props.value, props.row.values.TRANS_ID)} /> },
		{ Header: 'Service', accessor: 'SERVICE_NAME', width: 250 },
		{ Header: 'ID', accessor: 'TRANS_ID', width: 75 },
		{ Header: 'Date', accessor: 'TRANS_DATE', width: 100, format: 'mm/dd/yy' },
		{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 125 },
		{ Header: 'User Name/Group', accessor: 'USER_GROUP', width: 150 },
		{ Header: 'PI', accessor: 'S_PI_NAME', width: 150 },
		{ Header: 'Labor Hrs', accessor: 'LABORHRS', width: 100, format: '#,##0.00' },
		{ Header: '$ Material', accessor: 'MATERIAL', width: 100, format: '#,##0.00' },
		{ Header: 'Details', accessor: 'DETAILS', width: 250 },
		{ Header: 'Comments', accessor: 'COMMENTS', width: 400 }
	]
};

const unbilledExportColumns = [
	{ Header: 'Service', accessor: 'SERVICE_NAME', width: 50 },
	{ Header: 'ID', accessor: 'TRANS_ID', width: 15 },
	{ Header: 'Date', accessor: 'TRANS_DATE', width: 20 },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 25 },
	{ Header: 'User Name/Group', accessor: 'USER_GROUP', width: 30 },
	{ Header: 'PI', accessor: 'S_PI_NAME', width: 30 },
	{ Header: 'Labor Hrs', accessor: 'LABORHRS', width: 20 },
	{ Header: '$ Material', accessor: 'MATERIAL', width: 20 },
	{ Header: 'Details', accessor: 'DETAILS', width: 50 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 80 }
];

export default withRouter(Invalid);