import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';

class Home extends ChemComponent {	
	constructor(props) {
		super(props);
		
		// default action is APPLY, so remove username and password
		var initLoginColumns = this.removeColumnsByAccessor(loginColumns, ['AFFILIATION','USERNAME','PASSWORD']);		

		this.state = {
			login: {
				ACTION: null,
				AFFILIATION: null,
				USERNAME: '',
				PASSWORD: ''
			},
			loginColumns: initLoginColumns,
			renderKey: 1
		};
	}
	
	onChange(accessor, value) {
		var login = this.copyObject(this.state.login);
		var newLoginColumns = this.copyColumns(this.state.loginColumns);
		this.setByAccessor(login, accessor, value);
		// if the action has changed
		if (accessor === 'ACTION') {
			// clear anything that has been entered
			this.setByAccessor(login, 'AFFILIATION', null);
			this.setByAccessor(login, 'USERNAME', null);
			this.setByAccessor(login, 'PASSWORD', null);
			if (value === 'APPLY') {
				// remove username and password columns
				newLoginColumns = this.removeColumnsByAccessor(loginColumns, ['USERNAME','PASSWORD']);
			} else if (['USER','ADMIN'].includes(value)) {
				// remove affiliation
				newLoginColumns = this.removeColumnsByAccessor(loginColumns, ['AFFILIATION']);
			} else {
				// no selection - remove everything except ACTION
				newLoginColumns = this.removeColumnsByAccessor(loginColumns, ['AFFILIATION','USERNAME','PASSWORD']);
			}
		}
		
		this.setState({
			login: login,
			loginColumns: newLoginColumns,
			renderKey: this.state.renderKey + 1
		});
	}
	
	onSubmit(event) {
		var self = this;
		if (this.state.login.ACTION === 'APPLY') {
			// submit application
		} else {
			this.props.logIn(this.state.login.ACTION, this.state.login.USERNAME, this.state.login.PASSWORD, () => {
				if (self.state.login.ACTION === 'USER') {
					this.props.navigate('/FAS/UserHome');
				} else if (self.state.login.ACTION === 'ADMIN') {
					this.props.navigate('/FAS/AdminHome');
				}
			});
		}
	}

	render() {
		return(
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', paddingBottom: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold' }}>MicroAnalytical Lab Requests</div>
				  <ChemEdit parent={this} user={this.props.user} columns={this.state.loginColumns} data={this.state.login}
					renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)}
					onSubmit={(event) => this.onSubmit(event)}>
					{!this.isEmpty(this.state.login.ACTION) && <Form.Group as={Row}>
					  <Col>
						<Button id="save" variant="warning" type="submit" className="float-end">{this.state.login.ACTION === 'APPLY' ? 'Apply' : 'Log In'}</Button>
					  </Col>
					</Form.Group>}
				  </ChemEdit>	
				</Col>
			  </Row>
			</Container>
		);
	}
}

const loginColumns = [
	{ Header: 'Action', accessor: 'ACTION', type: 'select',
		options: [{ value: null, label: 'Select...' },{ value: 'APPLY', label: 'Apply for Access' },{ value: 'USER', label: 'Login as User' },{ value: 'ADMIN', label: 'Login as Admin' }] },
	{ Header: 'Affiliation', accessor: 'AFFILIATION', type: 'select',
		options: [{ value: null, label: 'Select...' },{ value: 'COC', label: 'College of Chemistry Users' },{ value: 'UC', label: 'UCB or UC Affiliate Users' },{ value: 'NON', label: 'Non-UC Users' }] },
	{ Header: 'Username', accessor: 'USERNAME' },
	{ Header: 'Password', accessor: 'PASSWORD', type: 'password' }
];

export default withRouter(Home);