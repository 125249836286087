import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../../ChemComponent';
import ChemEdit from '../../ChemEdit';
import QueryTable from '../../QueryTable';
import { Link } from 'react-router-dom';

class KeyDeposits extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var sevenYearsAgo = new Date();
		sevenYearsAgo.setYear(sevenYearsAgo.getFullYear() - 7);
		
		var search = {
			RETURN_DATE_FROM: this.dateTimeToMVC(sevenYearsAgo),
			RETURN_DATE_TO: null,
			PERSON_NAME: ''
		};
		
		var initKeyDepositList = this.copyTable(keyDepositList);
		initKeyDepositList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			keyDepositList: initKeyDepositList,
			search: search,
			searchCount: null
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newKeyDepositList = this.copyTable(this.state.keyDepositList);
		newKeyDepositList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			keyDepositList: newKeyDepositList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	generateInList(name, list) {
		var orClause = { Operator: 'or', Children: [] };
		
		for (var i = 0; i < list.length; i++) {
			orClause.Children.push({ Attribute: name, Operator: 'like', StringValue: '%|' + list[i] + '|%' });
		}
		
		return orClause;
	}
	
	constructSearch(data) {
		// for all searches: has a nonzero deposit and a return date but no deposit return date
		var search = {
			Operator: 'and',
			Children: [
				{ Attribute: 'DEPOSIT', Operator: '>', LongValue: 0 },
				{ Attribute: 'RETURN_DATE_CMP', Operator: 'is not null' },
				{ Attribute: 'RETURN_DEPOSIT', Operator: 'is null' },
				{ Attribute: 'STATUS_ID', Operator: 'not in', LongList: '1,5' } // exclude destroyed (1) and lost (5) keys
			]
		};

		if (!this.isEmpty(data.RETURN_DATE_FROM)) {
			search.Children.push({ Attribute: 'RETURN_DATE_CMP', Operator: '>=', DateValue: data.RETURN_DATE_FROM });
		}
		if (!this.isEmpty(data.RETURN_DATE_TO)) {
			search.Children.push({ Attribute: 'RETURN_DATE_CMP', Operator: '<=', DateValue: data.RETURN_DATE_TO });
		}
		if (!this.isEmpty(data.PERSON_NAME)) search.Children.push({ Attribute: 'PERSON_NAME', Operator: 'like', StringValue: '%' + data.PERSON_NAME + '%' });
		
		return search;
	}
	
	onTableSort(newOrder) {
		var newKeyDepositList = this.copyTable(this.state.keyDepositList);
		newKeyDepositList.query.order = newOrder;
		
		this.mergeState({
			keyDepositList: newKeyDepositList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
	}

	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Key Deposits Report',
			columns: exportColumns,
			entity: 'KEY_DEPOSITS_VW',
			search: this.constructSearch(this.state.search),
			order: ['KEY_ID', 'PERSON_NAME', 'PI_NAME']
		});

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Key Deposits Report</span>
					<ChemEdit parent={this} columns={keyDepositSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_KeyDeposits' table={this.state.keyDepositList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='190px' onTableSort={(newOrder) => this.onTableSort(newOrder)} />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const keyDepositSearch = [
	{ Header: 'Return Date From', accessor: 'RETURN_DATE_FROM', type: 'date', row: 0, col: 0 },
	{ Header: 'Return Date To', accessor: 'RETURN_DATE_TO', type: 'date', row: 0, col: 1 },
	{ Header: 'Name', accessor: 'PERSON_NAME', row: 0, col: 2 }
];

const keyDepositList = {
	query: { entity: 'KEY_DEPOSITS_VW', search: null, order: ['KEY_ID', 'PERSON_NAME', 'PI_NAME'] },
	pageSize: 20,
	columns: [
		{ Header: 'ID', accessor: 'KEY_ID', 
			Cell: props => <Link to={'/BuildingAccess/Keys/' + props.value}>{props.value}</Link> },
		{ Header: 'Key Series', accessor: 'TYPE_NAME' },
		{ Header: 'PERSON_ID', accessor: 'PERSON_ID', show: false },
		{ Header: 'Cal UID', accessor: 'CAL_UID' },
		{ Header: 'Name', accessor: 'PERSON_NAME',
			Cell: props => <Link to={'/BuildingAccess/People/' + props.row.values.PERSON_ID}>{props.value}</Link> },
		{ Header: 'Role', accessor: 'ROLE_TYPE' },
		{ Header: 'PI', accessor: 'PI_NAME' },
		{ Header: 'Status', accessor: 'PERSON_STATUS' },
		{ Header: 'Issued Date', accessor: 'OUT_DATE' },
		{ Header: 'Date Left', accessor: 'DATE_LEFT' },
		{ Header: 'Key Status', accessor: 'KEY_STATUS' },
		{ Header: 'Returned', accessor: 'RETURN_DATE' },
		{ Header: 'Deposit Paid', accessor: 'DEPOSIT' },
		{ Header: 'Payment Type', accessor: 'PAYMENT_TYPE' },
		{ Header: 'Comments', accessor: 'COMMENTS' }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'KEY_ADMIN_ID', width: 10 },
	{ Header: 'Key Series', accessor: 'TYPE_NAME', width: 20 },
	{ Header: 'Cal UID', accessor: 'CAL_UID', width: 15 },
	{ Header: 'Name', accessor: 'PERSON_NAME', width: 20 },
	{ Header: 'Role', accessor: 'ROLE_TYPE', width: 15 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 20 },
	{ Header: 'Status', accessor: 'PERSON_STATUS', width: 15 },
	{ Header: 'Issued Date', accessor: 'OUT_DATE', width: 15 },
	{ Header: 'Date Left', accessor: 'DATE_LEFT', width: 15 },
	{ Header: 'Key Status', accessor: 'KEY_STATUS', width: 20 },
	{ Header: 'Returned', accessor: 'RETURN_DATE', width: 15 },
	{ Header: 'Deposit Paid', accessor: 'DEPOSIT', width: 10 },
	{ Header: 'Payment Type', accessor: 'PAYMENT_TYPE', width: 10 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 20 }
];

export default KeyDeposits;