import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withHeLADDefaultRoute } from './withHeLADDefaultRoute';
import ChemHeader from '../ChemHeader';
import Restricted from '../Restricted';
import Confirm from '../Confirm';
import TreeMenu from '../TreeMenu';
import Help from './Help';
import Home from './Home';
import Helium from './Helium';
import DailyReadings from './DailyReadings';
import DeliveryAdmin from './DeliveryAdmin';
import Dewars from './Dewars';
import Dewar from './Dewar';
import Products from './Products';
import Product from './Product';
import Services from './Services';
import Service from './Service';
import LN2Transactions from './LN2Transactions';
import LN2Transaction from './LN2Transaction';
import LN2Upload from './LN2Upload';
import Cylinders from './Cylinders';
import Cylinder from './Cylinder';
import CylTransactions from './CylTransactions';
import CylTransaction from './CylTransaction';
import MeterReadings from './MeterReadings';
import GN2Meters from './GN2Meters';
import GN2Meter from './GN2Meter';
import GN2Users from './GN2Users';
import GN2User from './GN2User';
import GN2Transactions from './GN2Transactions';
import GN2Transaction from './GN2Transaction';
import GN2Upload from './GN2Upload';
import HeMeterReadings from './HeMeterReadings';
import HeMeters from './HeMeters';
import HeMeter from './HeMeter';
import HeUsers from './HeUsers';
import HeUser from './HeUser';
import HeTransactions from './HeTransactions';
import HeTransaction from './HeTransaction';
import HeUpload from './HeUpload';
import DemCylinders from './DemCylinders';
import DemCylinder from './DemCylinder';
import Vendors from './Vendors';
import Vendor from './Vendor';
import Rates from './Rates';
import Rate from './Rate';
import Gases from './Gases';
import Gas from './Gas';
import Access from './Access';
import Return from './Return';
import DemTransactions from './DemTransactions';
import DemTransaction from './DemTransaction';
import DryIceSales from './DryIceSales';
import DryIceSale from './DryIceSale';
import CylHistory from './CylHistory';
import ReconcileGN2Upload from './ReconcileGN2Upload';
import ReturnManifest from './ReturnManifest';
import CCS from './CCS';
import Settings from './Settings';
import DemSettings from './DemSettings';

class HeLAD extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var today = this.midnight(new Date());
		var thirtyDaysAgo = new Date(today);
		thirtyDaysAgo.setDate(today.getDate() - 30);

		// copy menu items so we can edit
		var stateTreeMenuItems = this.copyTreeMenuItems(treeMenuItems);
		
		// if the user is not authorized for liquid air
		if (!props.user.helad || props.user.helad.liquidAirAuthLevel < 1) {
			// remove all liquid air menu items
			this.removeRoute(stateTreeMenuItems, routes.cylinders);
			this.removeRoute(stateTreeMenuItems, routes.cylTransactions);
			this.removeRoute(stateTreeMenuItems, routes.dailyReadings);
			this.removeRoute(stateTreeMenuItems, routes.deliveryAdmin);
			this.removeRoute(stateTreeMenuItems, routes.dewars);
			this.removeRoute(stateTreeMenuItems, routes.gn2Meters);
			this.removeRoute(stateTreeMenuItems, routes.gn2Transactions);
			this.removeRoute(stateTreeMenuItems, routes.gn2Upload);
			this.removeRoute(stateTreeMenuItems, routes.gn2Users);
			this.removeRoute(stateTreeMenuItems, routes.heTransactions);
			this.removeRoute(stateTreeMenuItems, routes.heMeterReadings);
			this.removeRoute(stateTreeMenuItems, routes.heMeters);
			this.removeRoute(stateTreeMenuItems, routes.heUpload);
			this.removeRoute(stateTreeMenuItems, routes.heUsers);
			this.removeRoute(stateTreeMenuItems, routes.ln2Transactions);
			this.removeRoute(stateTreeMenuItems, routes.ln2Upload);
			this.removeRoute(stateTreeMenuItems, routes.meterReadings);
			this.removeRoute(stateTreeMenuItems, routes.products);
			this.removeRoute(stateTreeMenuItems, routes.services);
			this.removeRoute(stateTreeMenuItems, routes.settings);
			this.removeRouteByLabel(stateTreeMenuItems, 'Unfilled Orders');
			this.removeRouteByLabel(stateTreeMenuItems, 'Filled Orders');
			this.removeRouteByLabel(stateTreeMenuItems, 'Checked Out');
			this.removeRouteByLabel(stateTreeMenuItems, 'Returned');
		}
		
		// if the user is not authorized for demurrage
		if (!props.user.helad || props.user.helad.demurrageAuthLevel < 1) {
			// remove all demurrage menu items
			this.removeRoute(stateTreeMenuItems, routes.access);
			this.removeRoute(stateTreeMenuItems, routes.demCylinders);
			this.removeRoute(stateTreeMenuItems, routes.gases);
			this.removeRoute(stateTreeMenuItems, routes.rates);
			this.removeRoute(stateTreeMenuItems, routes.returnCyl);
			this.removeRoute(stateTreeMenuItems, routes.vendors);
			this.removeRoute(stateTreeMenuItems, routes.demTransactions);
			this.removeRoute(stateTreeMenuItems, routes.demPeople);
			this.removeRoute(stateTreeMenuItems, routes.dryIceSales);
			this.removeRoute(stateTreeMenuItems, routes.returnManifest);
			this.removeRoute(stateTreeMenuItems, routes.demSettings);
		}
		
		this.state = {
			fullScreen: this.startsWithIgnoreCase(props.location.pathname, '/HeLAD/CCS'),
			treeMenuItems: stateTreeMenuItems,
			dewarEditable: false,
			dewarSearch: {
				REQUEST_ID: null,
				PIID: null,
				DATE_REQUESTED_FROM: null,
				DATE_REQUESTED_TO: null,
				CUSTOMER: null,
				COST_CENTER: null,
				DEPARTMENT: null,
				CANCEL_FLAG: null,
				READY_FLAG: null,
				PICKED_UP: null,
				DATE_FILLED_FROM: null,
				DATE_FILLED_TO: null,
				DATE_BILLED: [],
				FLD_CANCEL_FLAG_DISPLAY: true,
				FLD_DATE_BILLED_DISPLAY: true,
				FLD_DATE_FILLED_DISPLAY: false,
				FLD_DATE_REQUESTED_DISPLAY: true,
				FLD_REQUESTED_DELIVERY_DATE_DISPLAY: true,
				FLD_DEPARTMENT: true,
				FLD_CONTAINER_ID: false,
				FLD_CONT_TYPE: true,
				FLD_EMAIL: false,
				FLD_FILLED: true,
				FLD_PICKED_UP_DISPLAY: false,
				FLD_PI_NAME: true,
				FLD_REMARKS: false,
				FLD_REQUEST_ID: true,
				FLD_CUSTOMER: false,
				FLD_COST_CENTER: true,
				FLD_COLD_FLAG_DISPLAY: false,
				FLD_VOLUME: false,
				treeMenuClickCount: 1
			},
			cylinderSearch: {
				CYLINDER_ID: null,
				SALE_STATUS: null,
				treeMenuClickCount: 1
			},
			demCylinderSearch: {
				CYL_CURRENTLY: null,
				CYL_ID: null,
				GAS_ID: null,
				RENTAL_RATE_ID: null,
				OWNER_ID: null,
				IOC_NUM_IN: '',
				DATE_IN_COLLEGE_FROM: null,
				DATE_IN_COLLEGE_TO: null,
				DATE_OUT_COLLEGE_FROM: null,
				DATE_OUT_COLLEGE_TO: null,
				TAG_NUM_IN: '',
				TAG_NUM_OUT: '',
				REC_NUM_IN: null,
				REC_NUM_OUT: null,
				treeMenuClickCount: 1
			},
			demTransactionSearch: {
				CYCLE_ID: '',
				GAS_ID: null,
				OWNER_ID: null,
				OUT_DATE_FROM: this.dateTimeToMVC(thirtyDaysAgo),
				OUT_DATE_TO: this.dateTimeToMVC(today),
				treeMenuClickCount: 1
			},
			productEditable: false,
			serviceEditable: false,
			ln2TransactionEditable: false,
			cylinderEditable: false,
			cylTransactionEditable: false,
			gn2MeterEditable: false,
			gn2UserEditable: false,
			gn2TransactionEditable: false,
			heTransactionEditable: false,
			heMeterEditable: false,
			heUserEditable: false,
			demCylinderEditable: false,
			vendorEditable: false,
			rateEditable: false,
			gasEditable: false,
			demTransactionEditable: false,
			confirm: ''
		};
	}
	
	removeRoute(menuItems, route) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRoute(menuItems[i].nodes, route);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].route && menuItems[i].route.toUpperCase() === route.toUpperCase()) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}
	
	removeRouteByLabel(menuItems, label) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRouteByLabel(menuItems[i].nodes, label);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].label && menuItems[i].label.toUpperCase() === label.toUpperCase()) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}
	
	showConfirmation(message) {
		var self = this;
		this.mergeState({
			confirm: message
		}, () => self.props.navigate('/HeLAD/Confirm'));
	}
	
	toggleFullScreen(fullScreen) {
		// if we are on checkout screen, full screen mode can't be turned off
		if (!this.startsWithIgnoreCase(this.props.location.pathname, '/HeLAD/CCS')) {
			this.mergeState({ fullScreen: fullScreen });
		}
	}

	onClickUnfilledOrders() {
		this.onDewarSearch({
			REQUEST_ID: null,
			PIID: null,
			DATE_REQUESTED_FROM: null,
			DATE_REQUESTED_TO: null,
			CUSTOMER: null,
			COST_CENTER: null,
			DEPARTMENT: null,
			CANCEL_FLAG: '0',
			READY_FLAG: '0',
			PICKED_UP: null,
			DATE_FILLED_FROM: null,
			DATE_FILLED_TO: null,
			DATE_BILLED: [],
			FLD_CANCEL_FLAG_DISPLAY: true,
			FLD_DATE_BILLED_DISPLAY: true,
			FLD_DATE_FILLED_DISPLAY: false,
			FLD_DATE_REQUESTED_DISPLAY: true,
			FLD_REQUESTED_DELIVERY_DATE_DISPLAY: true,
			FLD_DEPARTMENT: true,
			FLD_CONTAINER_ID: false,
			FLD_CONT_TYPE: true,
			FLD_EMAIL: false,
			FLD_FILLED: true,
			FLD_PICKED_UP_DISPLAY: false,
			FLD_PI_NAME: true,
			FLD_REMARKS: false,
			FLD_REQUEST_ID: true,
			FLD_CUSTOMER: false,
			FLD_COST_CENTER: true,
			FLD_COLD_FLAG_DISPLAY: false,
			FLD_VOLUME: false,
			treeMenuClickCount: this.state.dewarSearch.treeMenuClickCount + 1
		});
	}
	
	onClickFilledOrders() {
		// subtract one month from current date
		var d = new Date();
		var m = d.getMonth();
		d.setMonth(d.getMonth() - 1);

		// If still in the same month, this means we have rolled back to a non-existent date (like April 31st)
		// and it has rolled forward to be at the beginning of the month we started in. So set to last day of previous month.
		if (d.getMonth() === m) d.setDate(0);

		this.onDewarSearch({
			REQUEST_ID: null,
			PIID: null,
			DATE_REQUESTED_FROM: null,
			DATE_REQUESTED_TO: null,
			CUSTOMER: null,
			COST_CENTER: null,
			DEPARTMENT: null,
			CANCEL_FLAG: '0',
			READY_FLAG: '1',
			PICKED_UP: '2',
			DATE_FILLED_FROM: this.dateTimeToMVC(d),
			DATE_FILLED_TO: null,
			DATE_BILLED: [],
			FLD_CANCEL_FLAG_DISPLAY: true,
			FLD_DATE_BILLED_DISPLAY: true,
			FLD_DATE_FILLED_DISPLAY: false,
			FLD_DATE_REQUESTED_DISPLAY: true,
			FLD_REQUESTED_DELIVERY_DATE_DISPLAY: true,
			FLD_DEPARTMENT: true,
			FLD_CONTAINER_ID: false,
			FLD_CONT_TYPE: true,
			FLD_EMAIL: false,
			FLD_FILLED: true,
			FLD_PICKED_UP_DISPLAY: false,
			FLD_PI_NAME: true,
			FLD_REMARKS: false,
			FLD_REQUEST_ID: true,
			FLD_CUSTOMER: false,
			FLD_COST_CENTER: true,
			FLD_COLD_FLAG_DISPLAY: false,
			FLD_VOLUME: false,
			treeMenuClickCount: this.state.dewarSearch.treeMenuClickCount + 1
		});
	}
	
	onClickDewarSearch() {
		this.onDewarSearch({
			REQUEST_ID: null,
			PIID: null,
			DATE_REQUESTED_FROM: null,
			DATE_REQUESTED_TO: null,
			CUSTOMER: null,
			COST_CENTER: null,
			DEPARTMENT: null,
			CANCEL_FLAG: null,
			READY_FLAG: null,
			PICKED_UP: null,
			DATE_FILLED_FROM: null,
			DATE_FILLED_TO: null,
			DATE_BILLED: [],
			FLD_CANCEL_FLAG_DISPLAY: true,
			FLD_DATE_BILLED_DISPLAY: true,
			FLD_DATE_FILLED_DISPLAY: false,
			FLD_DATE_REQUESTED_DISPLAY: true,
			FLD_REQUESTED_DELIVERY_DATE_DISPLAY: true,
			FLD_DEPARTMENT: true,
			FLD_CONTAINER_ID: false,
			FLD_CONT_TYPE: true,
			FLD_EMAIL: false,
			FLD_FILLED: true,
			FLD_PICKED_UP_DISPLAY: false,
			FLD_PI_NAME: true,
			FLD_REMARKS: false,
			FLD_REQUEST_ID: true,
			FLD_CUSTOMER: false,
			FLD_COST_CENTER: true,
			FLD_COLD_FLAG_DISPLAY: false,
			FLD_VOLUME: false,
			treeMenuClickCount: this.state.dewarSearch.treeMenuClickCount + 1
		});		
	}
	
	onDewarSearch(dewarSearch) {
		var self = this;
		this.mergeState({
			dewarSearch: dewarSearch
		}, () => {
			if (self.props.match.path !== routes.dewars) {
				self.props.navigate(routes.dewars);
			}
		});
	}
	
	onClickCheckedOut() {
		this.onCylinderSearch({
			CONTAINER_ID: null,
			SALE_STATUS: 'OUT',
			treeMenuClickCount: this.state.cylinderSearch.treeMenuClickCount + 1
		});
	}
	
	onClickReturned() {
		this.onCylinderSearch({
			CONTAINER_ID: null,
			SALE_STATUS: 'IN',
			treeMenuClickCount: this.state.cylinderSearch.treeMenuClickCount + 1
		});
	}
	
	onClickCylinderSearch() {
		this.onCylinderSearch({
			CONTAINER_ID: null,
			SALE_STATUS: null,
			treeMenuClickCount: this.state.cylinderSearch.treeMenuClickCount + 1
		});		
	}
	
	onCylinderSearch(cylinderSearch) {
		var self = this;
		this.mergeState({
			cylinderSearch: cylinderSearch
		}, () => {
			if (self.props.match.path !== routes.cylinders) {
				self.props.navigate(routes.cylinders);
			}
		});
	}

	demCylindersForGas(gasId) {
		var self = this;
		this.mergeState({
			demCylinderSearch: {
				CYL_CURRENTLY: null,
				CYL_ID: null,
				GAS_ID: gasId,
				RENTAL_RATE_ID: null,
				OWNER_ID: null,
				IOC_NUM_IN: '',
				DATE_IN_COLLEGE_FROM: null,
				DATE_IN_COLLEGE_TO: null,
				DATE_OUT_COLLEGE_FROM: null,
				DATE_OUT_COLLEGE_TO: null,
				TAG_NUM_IN: '',
				TAG_NUM_OUT: '',
				REC_NUM_IN: null,
				REC_NUM_OUT: null,
				treeMenuClickCount: this.state.demCylinderSearch.treeMenuClickCount + 1
			}
		}, () => {
			if (self.props.match.path !== routes.demCylinders) {
				self.props.navigate(routes.demCylinders);
			}
		});
	}
	
	reconcileGN2Upload(meterReadings) {
		var self = this;
		this.mergeState({
			meterReadings: meterReadings
		}, () => self.props.navigate('/HeLAD/ReconcileGN2Upload'));
	}

	render() {
		return (<>
			<ChemHeader parent={this} user={this.props.user} title='Helium/Liquid Air/Demurrage' fullScreen={this.state.fullScreen} 
				toggleFullScreen={(fullScreen) => this.toggleFullScreen(fullScreen)}
				showHelp={() => this.props.navigate('/HeLAD/Help', { helpPath: this.props.location.pathname })}
				hideMenu={this.props.location.pathname.startsWith('/HeLAD/CCS')} />
			<Container fluid style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: '#e6e6e4', height: 'calc(100vh - 64px)', overflow: 'auto' }}>
				<Row>
					{!this.state.fullScreen && <Col xs={2}>
						<TreeMenu parent={this} menuItems={this.state.treeMenuItems} selectedRoute={this.props.location.pathname} />
					</Col>}
					<Col xs={this.state.fullScreen ? 12 : 10}>
						<Routes>
							<Route path={'/ccs'} element={<CCS parent={this} user={this.props.user} logIn={(cardmag) => this.props.logIn(cardmag)} />} />
							<Route path={'/ccs/:activeTab'} element={<CCS parent={this} user={this.props.user} logIn={(cardmag) => this.props.logIn(cardmag)} />} />
							<Route path={'/helium'} element={<Helium parent={this} user={this.props.user} />} />
							<Route path={'/dailyreadings'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<DailyReadings parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/deliveryadmin'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<DeliveryAdmin parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/dewars'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<Dewars parent={this} user={this.props.user} search={this.state.dewarSearch}
										setDewarEditable={(dewarEditable) => this.mergeState({ dewarEditable: dewarEditable })} />
								</Restricted>
							} />
							<Route path={'/dewars/:requestId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<Dewar parent={this} user={this.props.user} editable={this.state.dewarEditable} />
								</Restricted>
							} />
							<Route path={'/products'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<Products parent={this} user={this.props.user}
										setProductEditable={(productEditable) => this.mergeState({ productEditable: productEditable })} />
								</Restricted>
							} />
							<Route path={'/products/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<Product parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/products/:productId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<Product parent={this} user={this.props.user} editable={this.state.productEditable } />
								</Restricted>
							} />
							<Route path={'/services'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<Services parent={this} user={this.props.user}
										setServiceEditable={(serviceEditable) => this.mergeState({ serviceEditable: serviceEditable })} />
								</Restricted>
							} />
							<Route path={'/services/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<Service parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/services/:serviceId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<Service parent={this} user={this.props.user} editable={this.state.serviceEditable } />
								</Restricted>
							} />
							<Route path={'/ln2transactions'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<LN2Transactions parent={this} user={this.props.user}
										setLN2TransactionEditable={(ln2TransactionEditable) => this.mergeState({ ln2TransactionEditable: ln2TransactionEditable })} />
								</Restricted>
							} />
							<Route path={'/ln2transactions/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<LN2Transaction parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/ln2transactions/:transId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<LN2Transaction parent={this} user={this.props.user} editable={this.state.ln2TransactionEditable } />
								</Restricted>
							} />
							<Route path={'/ln2upload'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<LN2Upload parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/cylinders'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<Cylinders parent={this} user={this.props.user} search={this.state.cylinderSearch}
										setCylinderEditable={(cylinderEditable) => this.mergeState({ cylinderEditable: cylinderEditable })} />
								</Restricted>
							} />
							<Route path={'/cylinders/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<Cylinder parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/cylinders/:cylinderId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<Cylinder parent={this} user={this.props.user} editable={this.state.cylinderEditable} />
								</Restricted>
							} />
							<Route path={'/cyltransactions'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<CylTransactions parent={this} user={this.props.user}
										setCylTransactionEditable={(cylTransactionEditable) => this.mergeState({ cylTransactionEditable: cylTransactionEditable })} />
								</Restricted>
							} />
							<Route path={'/cyltransactions/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<CylTransaction parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/cyltransactions/:transId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<CylTransaction parent={this} user={this.props.user} editable={this.state.cylTransactionEditable } />
								</Restricted>
							} />
							<Route path={'/meterreadings'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<MeterReadings parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/gn2meters'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<GN2Meters parent={this} user={this.props.user}
										setGN2MeterEditable={(gn2MeterEditable) => this.mergeState({ gn2MeterEditable: gn2MeterEditable })} />
								</Restricted>
							} />
							<Route path={'/gn2meters/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<GN2Meter parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/gn2meters/:meterId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<GN2Meter parent={this} user={this.props.user} editable={this.state.gn2MeterEditable} />
								</Restricted>
							} />
							<Route path={'/gn2users'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<GN2Users parent={this} user={this.props.user}
										setGN2MeterEditable={(gn2MeterEditable) => this.mergeState({ gn2MeterEditable: gn2MeterEditable })}
										setGN2UserEditable={(gn2UserEditable) => this.mergeState({ gn2UserEditable: gn2UserEditable })} />
								</Restricted>
							} />
							<Route path={'/gn2users/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<GN2User parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/gn2users/:rechargeId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<GN2User parent={this} user={this.props.user} editable={this.state.gn2UserEditable} />
								</Restricted>
							} />
							<Route path={'/gn2transactions'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<GN2Transactions parent={this} user={this.props.user}
										setGN2TransactionEditable={(gn2TransactionEditable) => this.mergeState({ gn2TransactionEditable: gn2TransactionEditable })}
										setGN2MeterEditable={(gn2MeterEditable) => this.mergeState({ gn2MeterEditable: gn2MeterEditable })} />
								</Restricted>
							} />
							<Route path={'/gn2transactions/:transId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<GN2Transaction parent={this} user={this.props.user} editable={this.state.gn2TransactionEditable} />
								</Restricted>
							} />
							<Route path={'/gn2upload'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<GN2Upload parent={this} user={this.props.user} reconcileGN2Upload={(meterReadings) => this.reconcileGN2Upload(meterReadings)} />
								</Restricted>
							} />
							<Route path={'/reconcilegn2upload'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<ReconcileGN2Upload parent={this} user={this.props.user} meterReadings={this.state.meterReadings} />
								</Restricted>
							} />							
							<Route path={'/hemeterreadings'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<HeMeterReadings parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/hemeters'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<HeMeters parent={this} user={this.props.user}
										setHeMeterEditable={(heMeterEditable) => this.mergeState({ heMeterEditable: heMeterEditable })} />
								</Restricted>
							} />
							<Route path={'/hemeters/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<HeMeter parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/hemeters/:meterId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<HeMeter parent={this} user={this.props.user} editable={this.state.heMeterEditable} />
								</Restricted>
							} />
							<Route path={'/heusers'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<HeUsers parent={this} user={this.props.user}
										setHeMeterEditable={(heMeterEditable) => this.mergeState({ heMeterEditable: heMeterEditable })}
										setHeUserEditable={(heUserEditable) => this.mergeState({ heUserEditable: heUserEditable })} />
								</Restricted>
							} />
							<Route path={'/heusers/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<HeUser parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/heusers/:rechargeId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<HeUser parent={this} user={this.props.user} editable={this.state.heUserEditable} />
								</Restricted>
							} />
							<Route path={'/hetransactions'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<HeTransactions parent={this} user={this.props.user}
										setHeTransactionEditable={(heTransactionEditable) => this.mergeState({ heTransactionEditable: heTransactionEditable })}
										setHeMeterEditable={(heMeterEditable) => this.mergeState({ heMeterEditable: heMeterEditable })} />
								</Restricted>
							} />
							<Route path={'/hetransactions/:transId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<HeTransaction parent={this} user={this.props.user} editable={this.state.heTransactionEditable} />
								</Restricted>
							} />
							<Route path={'/heupload'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<HeUpload parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/settings'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.liquidAirAuthLevel > 0}>
									<Settings parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/demcylinders'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<DemCylinders parent={this} user={this.props.user} search={this.state.demCylinderSearch}
										setCylinderEditable={(demCylinderEditable) => this.mergeState({ demCylinderEditable: demCylinderEditable })} />
								</Restricted>
							} />
							<Route path={'/demcylinders/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<DemCylinder parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/demcylinders/:cylinderId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<DemCylinder parent={this} user={this.props.user} editable={this.state.demCylinderEditable} />
								</Restricted>
							} />
							<Route path={'/vendors'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<Vendors parent={this} user={this.props.user}
										setVendorEditable={(vendorEditable) => this.mergeState({ vendorEditable: vendorEditable })} />
								</Restricted>
							} />
							<Route path={'/vendors/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<Vendor parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/vendors/:vendorId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<Vendor parent={this} user={this.props.user} editable={this.state.vendorEditable} />
								</Restricted>
							} />
							<Route path={'/rates'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>								
									<Rates parent={this} user={this.props.user}
										setRateEditable={(rateEditable) => this.mergeState({ rateEditable: rateEditable })} />
								</Restricted>
							} />
							<Route path={'/rates/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<Rate parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/rates/:rateId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<Rate parent={this} user={this.props.user} editable={this.state.rateEditable} />
								</Restricted>
							} />
							<Route path={'/gases'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<Gases parent={this} user={this.props.user}
										setGasEditable={(gasEditable) => this.mergeState({ gasEditable: gasEditable })}
										demCylindersForGas={(gasId) => this.demCylindersForGas(gasId)} />
								</Restricted>
							} />										
							<Route path={'/gases/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<Gas parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/gases/:gasId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<Gas parent={this} user={this.props.user} editable={this.state.gasEditable} />
								</Restricted>
							} />
							<Route path={'/access'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<Access parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/return'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<Return parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/demsettings'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<DemSettings parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/demtransactions'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<DemTransactions parent={this} user={this.props.user} search={this.state.demTransactionSearch}
										setDemTransactionEditable={(demTransactionEditable) => this.mergeState({ demTransactionEditable: demTransactionEditable })} />
								</Restricted>
							} />
							<Route path={'/demtransactions/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<DemTransaction parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/demtransactions/:transId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<DemTransaction parent={this} user={this.props.user} editable={this.state.demTransactionEditable } />
								</Restricted>
							} />
							<Route path={'/cylhistory/:cylinderId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<CylHistory parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/dryicesales'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<DryIceSales parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/dryicesales/new'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<DryIceSale parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/dryicesales/:transId'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<DryIceSale parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/returnmanifest'} element={
								<Restricted authorized={this.props.user.helad && this.props.user.helad.demurrageAuthLevel > 0}>
									<ReturnManifest parent={this} user={this.props.user}
										setDemTransactionEditable={(demTransactionEditable) => 
										this.mergeState({ demTransactionEditable: demTransactionEditable })} />
								</Restricted>
							} />
							<Route path={'/confirm'} element={<Confirm parent={this} user={this.props.user} message={this.state.confirm} />} />
							<Route path={'/help'} element={<Help parent={this} user={this.props.user} />} />
							<Route path={'/home'} element={<Home parent={this} user={this.props.user} />} />
						</Routes>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

// routes defined here so there are no accidents when removing them!
const routes = {
	access: '/HeLAD/Access',
	cylinders: '/HeLAD/Cylinders',
	cylTransactions: '/HeLAD/CylTransactions',
	dailyReadings: '/HeLAD/DailyReadings',
	deliveryAdmin: '/HeLAD/DeliveryAdmin',
	demCylinders: '/HeLAD/DemCylinders',
	demPeople: '/BuildingAccess/People',
	demSettings: '/HeLAD/DemSettings',
	demTransactions: '/HeLAD/DemTransactions',
	dewars: '/HeLAD/Dewars',
	dryIceSales: '/HeLAD/DryIceSales',
	gases: '/HeLAD/Gases',
	gn2Meters: '/HeLAD/GN2Meters',
	gn2Transactions: '/HeLAD/GN2Transactions',
	gn2Upload: '/HeLAD/GN2Upload',
	gn2Users: '/HeLAD/GN2Users',
	helium: '/HeLAD/Helium',
	heTransactions: '/HeLAD/HeTransactions',
	heMeterReadings: '/HeLAD/HeMeterReadings',
	heMeters: '/HeLAD/HeMeters',
	heUpload: '/HeLAD/HeUpload',
	heUsers: '/HeLAD/HeUsers',
	home: '/HeLAD/Home',
	ln2Transactions: '/HeLAD/LN2Transactions',
	ln2Upload: '/HeLAD/LN2Upload',
	meterReadings: '/HeLAD/MeterReadings',
	products: '/HeLAD/Products',
	rates: '/HeLAD/Rates',
	receiveCyl: '/HeLAD/DemCylinders/new',
	returnCyl: '/HeLAD/Return',
	returnManifest: '/HeLAD/ReturnManifest',
	services: '/HeLAD/Services',
	settings: '/HeLAD/Settings',
	vendors: '/HeLAD/Vendors'
};

const treeMenuItems = [
	{
		label: 'Home',
		route: routes.home
	},
	{
		label: 'Helium Dewars',
		nodes: [
			{
				label: 'Helium Request',
				route: routes.helium
			},
			{
				label: 'Search',
				route: routes.dewars,
				onClick: props => props.parent.onClickDewarSearch()
			},
			{
				label: 'Unfilled Orders',
				onClick: props => props.parent.onClickUnfilledOrders()
			},
			{
				label: 'Filled Orders',
				onClick: props => props.parent.onClickFilledOrders()
			}
		]
	},
	/* this section removed as per Steve W. 1/16/24
	{
		label: 'Helium Cylinders',
		nodes: [
			{
				label: 'Search',
				route: routes.cylinders,
				onClick: props => props.parent.onClickCylinderSearch()
			},
			{
				label: 'Checked Out',
				onClick: props => props.parent.onClickCheckedOut()
			},
			{
				label: 'Returned',
				onClick: props => props.parent.onClickReturned()
			},
			{
				label: 'Transactions',
				route: routes.cylTransactions
			}
		]
	},
	*/
	{
		label: 'Helium Recovery',
		nodes: [
			{
				label: 'Meter Readings',
				route: routes.heMeterReadings
			},
			{
				label: 'Meters',
				route: routes.heMeters
			},
			{
				label: 'Users',
				route: routes.heUsers
			},
			{
				label: 'Transactions',
				route: routes.heTransactions
			},
			{
				label: 'Upload',
				route: routes.heUpload
			}
		]
	},
	{
		label: 'LN2 Dispenser',
		nodes: [
			{
				label: 'LN2 Transactions',
				route: routes.ln2Transactions
			},
			{
				label: 'Upload Monthly Data',
				route: routes.ln2Upload
			}
		]
	},
	{
		label: 'Gaseous Nitrogen',
		nodes: [
			{
				label: 'Meter Readings',
				route: routes.meterReadings
			},
			{
				label: 'Meters',
				route: routes.gn2Meters
			},
			{
				label: 'Users',
				route: routes.gn2Users
			},
			{
				label: 'Transactions',
				route: routes.gn2Transactions
			},
			{
				label: 'Upload',
				route: routes.gn2Upload
			}
		]
	},
	{
		label: 'Demurrage',
		nodes: [
			{
				label: 'Cylinders',
				route: routes.demCylinders
			},
			{
				label: 'Vendors',
				route: routes.vendors
			},
			{
				label: 'Rates',
				route: routes.rates
			},
			{
				label: 'Gases',
				route: routes.gases
			},
			{
				label: 'Card Access',
				route: routes.access
			},
			{
				label: 'Receive Cylinders',
				route: routes.receiveCyl
			},
			{
				label: 'Return Cylinders',
				route: routes.returnCyl
			},
			{
				label: 'Return Manifests',
				route: routes.returnManifest
			},
			{
				label: 'Transactions',
				route: routes.demTransactions
			},
			{
				label: 'Dry Ice Sales',
				route: routes.dryIceSales
			},
			{
				label: 'People',
				route: routes.demPeople
			},
			{
				label: 'Settings',
				route: routes.demSettings
			}
		]
	},
	{
		label: 'Administration',
		nodes: [
			{
				label: 'Daily Readings',
				route: routes.dailyReadings
			},
			{
				label: 'Enter Delivery',
				route: routes.deliveryAdmin
			},
			{
				label: 'Products',
				route: routes.products
			},
			{
				label: 'Services',
				route: routes.services
			},
			{
				label: 'Settings',
				route: routes.settings
			}
		]
	}
];

export default withHeLADDefaultRoute(HeLAD);