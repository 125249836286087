import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withStoresDefaultRoute } from './withStoresDefaultRoute';
import ChemHeader from '../ChemHeader';
import Restricted from '../Restricted';
import Confirm from '../Confirm';
import TreeMenu from '../TreeMenu';
import Help from './Help';
import Home from './Home';
import Catalog from './Catalog';
import Item from './Item';
import Sales from './Sales';
import Sale from './Sale';
import POs from './POs';
import PO from './PO';
import Receive from './Receive';
import TaxRates from './TaxRates';
import TaxRate from './TaxRate';
import MarkupRates from './MarkupRates';
import MarkupRate from './MarkupRate';
import NewItemReport from './NewItemReport';
import ReorderReport from './ReorderReport';
import CardSwipeReport from './CardSwipeReport';
import AdHocReport from './AdHocReport';
import InventoryUpload from './InventoryUpload';
import Vendors from './Vendors';
import Vendor from './Vendor';
import BearBuyUpload from './BearBuyUpload';
import Settings from './Settings';
import Checkout from './Checkout';

class Stores extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			fullScreen: this.startsWithIgnoreCase(props.location.pathname, '/Stores/Checkout'),
			treeMenuItems: props.user.stores && props.user.stores.authLevel > 0 ? treeMenuItems : [],
			itemEditable: false,
			saleEditable: false,
			poEditable: false,
			catalogSearch: null,
			salesSearch: null,
			poSearch: null,
			taxRateEditable: false,
			markupRateEditable: false,
			vendorEditable: false,
			confirm: ''
		};
	}

	removeRoute(menuItems, route) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRoute(menuItems[i].nodes, route);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].route && menuItems[i].route.toUpperCase() === route.toUpperCase()) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}
	
	removeRouteByLabel(menuItems, label) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRouteByLabel(menuItems[i].nodes, label);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].label && menuItems[i].label.toUpperCase() === label.toUpperCase()) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}
	
	toggleFullScreen(fullScreen) {
		// if we are on checkout screen, full screen mode can't be turned off
		if (!this.startsWithIgnoreCase(this.props.location.pathname, '/Stores/Checkout')) {
			this.mergeState({ fullScreen: fullScreen });
		}
	}
	
	onCatalogSearch(catalogSearch) {
		var self = this;
		this.mergeState({
			catalogSearch: catalogSearch
		}, () => self.props.navigate('/Stores/Catalog'));
	}
	
	onSalesSearch(salesSearch) {
		var self = this;
		this.mergeState({
			salesSearch: salesSearch
		}, () => self.props.navigate('/Stores/Sales'));
	}

	onPOSearch(poSearch) {
		var self = this;
		this.mergeState({
			poSearch: poSearch
		}, () => self.props.navigate('/Stores/POs'));
	}

	showConfirmation(message) {
		var self = this;
		this.mergeState({
			confirm: message
		}, () => self.props.navigate('/Stores/Confirm'));
	}

	render() {
		return (<>
			<ChemHeader parent={this} user={this.props.user} title='Stores' fullScreen={this.state.fullScreen} 
				toggleFullScreen={(fullScreen) => this.toggleFullScreen(fullScreen)}
				showHelp={() => this.props.navigate('/Stores/Help', { helpPath: this.props.location.pathname })}
				hideMenu={this.props.location.pathname.startsWith('/Stores/Checkout')} />
			<Container fluid style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: '#e6e6e4', height: 'calc(100vh - 64px)', overflow: 'auto' }}>
				<Row>
					{!this.state.fullScreen && <Col xs={2}>
						<TreeMenu parent={this} menuItems={this.state.treeMenuItems} selectedRoute={this.props.location.pathname} />
					</Col>}
					<Col xs={this.state.fullScreen? 12 : 10}>
						<Routes>
							<Route path={'/checkout'} element={<Checkout parent={this} user={this.props.user} logIn={(cardmag) => this.props.logIn(cardmag)} logOut={false} />} />
							<Route path={'/checkout/logout'} element={<Checkout parent={this} user={this.props.user} logIn={(cardmag) => this.props.logIn(cardmag)} logOut={true} />} />
							<Route path={'/catalog'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>								
									<Catalog parent={this} user={this.props.user} search={this.state.catalogSearch}
										setItemEditable={(itemEditable) => this.mergeState({ itemEditable: itemEditable })} />
								</Restricted>
							} />
							<Route path={'/catalog/new'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Item parent={this} user={this.props.user} editable={true}
										onCatalogSearch={(catalogSearch) => this.onCatalogSearch(catalogSearch)} />
								</Restricted>
							} />
							<Route path={'/catalog/new/:activeTab'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Item parent={this} user={this.props.user} editable={true}
										onCatalogSearch={(catalogSearch) => this.onCatalogSearch(catalogSearch)} />
								</Restricted>
							} />
							<Route path={'/catalog/:itemId'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Item parent={this} user={this.props.user} editable={this.state.itemEditable}
										onCatalogSearch={(catalogSearch) => this.onCatalogSearch(catalogSearch)} />
								</Restricted>
							} />
							<Route path={'/catalog/:itemId/:activeTab'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Item parent={this} user={this.props.user} editable={this.state.itemEditable}
										onCatalogSearch={(catalogSearch) => this.onCatalogSearch(catalogSearch)} />
								</Restricted>
							} />
							<Route path={'/sales'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>								
									<Sales parent={this} user={this.props.user} search={this.state.salesSearch}
										setSaleEditable={(saleEditable) => this.mergeState({ saleEditable: saleEditable })} />
								</Restricted>
							} />
							<Route path={'/sales/new'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Sale parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/sales/:saleId'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Sale parent={this} user={this.props.user} editable={this.state.saleEditable} />
								</Restricted>
							} /> 
							<Route path={'/pos'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<POs parent={this} user={this.props.user} search={this.state.poSearch}
										setPOEditable={(poEditable) => this.mergeState({ poEditable: poEditable })} />
								</Restricted>
							} />
							<Route path={'/pos/new'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<PO parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/pos/:poId'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<PO parent={this} user={this.props.user} editable={this.state.poEditable} />
								</Restricted>
							} />
							<Route path={'/receive/:poId'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Receive parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/taxrates'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>								
									<TaxRates parent={this} user={this.props.user}
										setTaxRateEditable={(taxRateEditable) => this.mergeState({ taxRateEditable: taxRateEditable })} />
								</Restricted>
							} />
							<Route path={'/taxrates/new'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<TaxRate parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/taxrates/:taxRateId'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<TaxRate parent={this} user={this.props.user} editable={this.state.taxRateEditable} />
								</Restricted>
							} />
							<Route path={'/markuprates'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<MarkupRates parent={this} user={this.props.user}
										setMarkupRateEditable={(markupRateEditable) => this.mergeState({ markupRateEditable: markupRateEditable })} />
								</Restricted>
							} />
							<Route path={'/markuprates/new'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<MarkupRate parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/markuprates/:markupRateId'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<MarkupRate parent={this} user={this.props.user} editable={this.state.markupRateEditable} />
								</Restricted>
							} />
							<Route path={'/inventoryupload'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<InventoryUpload parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/newitemreport'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>								
									<NewItemReport parent={this} user={this.props.user}
										setItemEditable={(itemEditable) => this.mergeState({ itemEditable: itemEditable })} />
								</Restricted>
							} />
							<Route path={'/reorderreport'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>								
									<ReorderReport parent={this} user={this.props.user}
										setItemEditable={(itemEditable) => this.mergeState({ itemEditable: itemEditable })}
										setPOEditable={(poEditable) => this.mergeState({ poEditable: poEditable })} />
								</Restricted>
							} />
							<Route path={'/cardswipereport'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>								
									<CardSwipeReport parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/adhocreport'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<AdHocReport parent={this} user={this.props.user}
										setItemEditable={(itemEditable) => this.mergeState({ itemEditable: itemEditable })} />
								</Restricted>
							} />
							<Route path={'/vendors'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Vendors parent={this} user={this.props.user}
										setVendorEditable={(vendorEditable) => this.mergeState({ vendorEditable: vendorEditable })} />
								</Restricted>
							} />
							<Route path={'/vendors/new'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Vendor parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/vendors/:vendorId'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Vendor parent={this} user={this.props.user} editable={this.state.vendorEditable} />
								</Restricted>
							} />
							<Route path={'/bearbuyupload'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<BearBuyUpload parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/settings'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Settings parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/confirm'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Confirm parent={this} user={this.props.user} message={this.state.confirm} />
								</Restricted>
							} />
							<Route path={'/help'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Help parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/home'} element={
								<Restricted authorized={this.props.user.stores && this.props.user.stores.authLevel > 0}>
									<Home parent={this} user={this.props.user} 
										onCatalogSearch={(catalogSearch) => this.onCatalogSearch(catalogSearch)}
										onSalesSearch={(salesSearch) => this.onSalesSearch(salesSearch)}
										onPOSearch={(poSearch) => this.onPOSearch(poSearch)} />
								</Restricted>
							} />
						</Routes>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

// routes defined here so there are no accidents when removing them!
const routes = {
	access: '/BuildingAccess/StoresAccess',
	adHoc: '/Stores/AdHocReport',
	approval: '/BuildingAccess/StoresApproval',
	bearBuyUpload: '/Stores/BearBuyUpload',
	cardSwipes: '/Stores/CardSwipeReport',
	catalog: '/Stores/Catalog',
	home: '/Stores/Home',
	inventoryUpload: '/Stores/InventoryUpload',
	markupRates: '/Stores/MarkupRates',
	newItems: '/Stores/NewItemReport',
	people: '/BuildingAccess/People',
	pos: '/Stores/POs',
	reorder: '/Stores/ReorderReport',
	sales: '/Stores/Sales',
	settings: '/Stores/Settings',
	taxRates: '/Stores/TaxRates',
	vendors: '/Stores/Vendors'
};

const treeMenuItems = [
	{
		label: 'Home',
		route: routes.home
	},
	{
		label: 'Catalog',
		route: routes.catalog
	},
	{
		label: 'Sales',
		route: routes.sales
	},
	{
		label: 'POs',
		route: routes.pos
	},
	{
		label: 'Uploads',
		nodes: [
			{
				label: 'Inventory',
				route: routes.inventoryUpload
			},
			{
				label: 'BearBuy',
				route: routes.bearBuyUpload
			}
		]
	},
	{
		label: 'Reports',
		nodes: [
			{
				label: 'New Items',
				route: routes.newItems
			},
			{
				label: 'Reorder',
				route: routes.reorder
			},
			{
				label: 'Card Swipes',
				route: routes.cardSwipes
			},
			{
				label: 'Ad Hoc',
				route: routes.adHoc
			},
		]
	},
	{
		label: 'Admin',
		nodes: [
			{
				label: 'People Search',
				route: routes.people
			},
			{
				label: 'Storeroom Approval',
				route: routes.approval
			},
			{
				label: 'Stores Access List',
				route: routes.access
			},
			{
				label: 'Vendors',
				route: routes.vendors
			},
			{
				label: 'Tax Rates',
				route: routes.taxRates
			},
			{
				label: 'Markup Rates',
				route: routes.markupRates
			},
			{
				label: 'Settings',
				route: routes.settings
			}
		]
	}
];

export default withStoresDefaultRoute(Stores);