import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withReuseDefaultRoute } from './withReuseDefaultRoute';
import ChemHeader from '../ChemHeader';
import Restricted from '../Restricted';
import Confirm from '../Confirm';
import TreeMenu from '../TreeMenu';
import Help from './Help';
import Home from './Home';
import Search from './Search';
import Bottles from './Bottles';
import Bottle from './Bottle';
import Orders from './Orders';
import Order from './Order';
import ShoppingCart from './ShoppingCart';

class Reuse extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var newTreeMenuItems = this.copyObject(treeMenuItems);
		
		// remove anything not authorized for the general public
		if (props.user.reuse.authLevel < 1) {
			this.removeRoute(newTreeMenuItems, routes.bottles);
			this.removeRoute(newTreeMenuItems, routes.orders);
			this.removeRouteByLabel(newTreeMenuItems, 'Admin');
		} else {
			// add hidden elements to Admin form
			var adminMenuItem = this.findMenuItemByLabel(newTreeMenuItems, 'Admin');
			if (adminMenuItem !== null) {
				if (this.isDevelopment()) {
					adminMenuItem.action = 'http://localhost:8000' + adminMenuItem.action;
				}
				adminMenuItem.data = {
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					redirect: '/chempy/admin/reuse'
				};
			}
		}
		
		this.state = {
			treeMenuItems: newTreeMenuItems,
			shoppingCart: [],
			bottleEditable: false,
			locationConfirmed: false,
			fullScreen: false,
			menuWidth: 2,
			renderKey: 1,
			confirm: ''
		};
	}
	
	addToCart(item) {
		// find the max existing id
		var i;
		var maxId = -1;
		for (i = 0; i < this.state.shoppingCart.length; i++) {
			if (this.state.shoppingCart[i].id > maxId) maxId = this.state.shoppingCart[i].id;
		}
		
		// create new cart item
		var cartItem = {
			id: maxId + 1,
			ITEM_ID: item.ITEM_ID,
			QTY: 1,
			STATUS_FLAG: 1,
			ITEM_DESC: item.CHEM_NAME + ' (BottleID: ' + item.BOTTLE_ID + ')'
		};
		
		// copy state and add new cart item
		var newState = this.copyObject(this.state);
		newState.shoppingCart.push(cartItem);
		
		// assign new line numbers
		for (i = 0; i < newState.shoppingCart.length; i++) {
			newState.shoppingCart[i].LINE_ID = i + 1;
		}
		this.setState(newState);
	}
	
	itemInCart(itemId) {
		for (var i = 0; i < this.state.shoppingCart.length; i++) {
			if (this.state.shoppingCart[i].ITEM_ID === itemId) return true;
		}
		return false;
	}
	
	removeFromCart(itemId) {
		var self = this;
		
		if (itemId === undefined) {
			this.showOKCancel('Confirm Clear Cart', 'Remove all items from shopping cart?', (okClicked) => {
				if (okClicked) {
					var newState = self.copyObject(self.state);
					newState.shoppingCart = [];
					self.setState(newState);
				}
			});
		} else {
			var i;

			// copy state and remove shopping cart item
			var newState = this.copyObject(this.state);
			for (i = 0; i < newState.shoppingCart.length; i++) {
				if (newState.shoppingCart[i].ITEM_ID === itemId) {
					newState.shoppingCart.splice(i, 1);
					break;
				}
			}
			
			// assign new line numbers
			for (i = 0; i < newState.shoppingCart.length; i++) {
				newState.shoppingCart[i].LINE_ID = i + 1;
			}
			this.setState(newState);
		}
	}
	
	setMenuWidth(newWidth) {
		if (newWidth > 12) newWidth = 12;
		else if (newWidth < 1) newWidth = 1;
		
		this.mergeState({
			menuWidth: newWidth,
			renderKey: this.state.renderKey + 1
		});
	}

	removeRoute(menuItems, route) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRoute(menuItems[i].nodes, route);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].route && menuItems[i].route.toUpperCase() === route.toUpperCase()) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}
	
	removeRouteByLabel(menuItems, label) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRouteByLabel(menuItems[i].nodes, label);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].label && menuItems[i].label.toUpperCase() === label.toUpperCase()) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}

	findMenuItemByLabel(menuItems, label) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				var subItem = this.findMenuItemByLabel(menuItems[i].nodes, label);
				if (subItem !== null) return subItem;
			} else {
				if (menuItems[i].label && menuItems[i].label.toUpperCase() === label.toUpperCase()) {
					return menuItems[i];
				}
			}
		}
		
		return null;
	}

	showConfirmation(message) {
		var self = this;
		this.mergeState({
			confirm: message
		}, () => self.props.navigate('/Reuse/Confirm'));
	}

	render() {
		return (<>
			<ChemHeader parent={this} user={this.props.user} title='Reuse Facility' fullScreen={this.state.fullScreen} 
				toggleFullScreen={(fullScreen) => this.mergeState({ fullScreen: fullScreen, renderKey: this.state.renderKey + 1 })}
				showHelp={() => this.props.navigate('/Reuse/Help', { helpPath: this.props.location.pathname })} />
			<Container fluid style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: '#e6e6e4', height: 'calc(100vh - 64px)', overflow: 'auto' }}>
				<Row>
					{!this.state.fullScreen && <Col xs={this.state.menuWidth}>
						<TreeMenu parent={this} menuItems={this.state.treeMenuItems} selectedRoute={this.props.location.pathname} />
						{this.state.locationConfirmed && <ShoppingCart parent={this} user={this.props.user} 
							shoppingCart={this.state.shoppingCart} menuWidth={this.state.menuWidth}
							setMenuWidth={(newWidth) => this.setMenuWidth(newWidth)}
							removeFromCart={(itemId) => this.removeFromCart(itemId)} />}
					</Col>}
					<Col xs={this.state.fullScreen ? 12 : (12 - this.state.menuWidth)}>
						<Routes>
							<Route path={'/home'} element={
								<Home parent={this} user={this.props.user} confirmLocation={(locationConfirmed) => this.mergeState({ locationConfirmed: locationConfirmed })} />
							} />
							<Route path={'/search'} element={
								<Restricted authorized={this.state.locationConfirmed} redirect='/Reuse/Home'>
									<Search parent={this} user={this.props.user} 
										setBottleEditable={(bottleEditable) => this.mergeState({ bottleEditable: bottleEditable })}
										locationConfirmed={this.state.locationConfirmed}
										addToCart={(item) => this.addToCart(item)}
										itemInCart={(itemId) => this.itemInCart(itemId)}
										removeFromCart={(itemId) => this.removeFromCart(itemId)} />
								</Restricted>
							} />
							<Route path={'/bottles'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Bottles parent={this} user={this.props.user} 
										setBottleEditable={(bottleEditable) => this.mergeState({ bottleEditable: bottleEditable })} />
								</Restricted>
							} />
							<Route path={'/bottles/new'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Bottle parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/bottles/new/:activeTab'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Bottle parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/bottles/:itemId'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Bottle parent={this} user={this.props.user} editable={this.state.bottleEditable }
										addToCart={(item) => this.addToCart(item)} 
										itemInCart={(itemId) => this.itemInCart(itemId)}
										removeFromCart={(itemId) => this.removeFromCart(itemId)} />
								</Restricted>
							} />
							<Route path={'/bottles/:itemId/:activeTab'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Bottle parent={this} user={this.props.user} editable={this.state.bottleEditable }
										addToCart={(item) => this.addToCart(item)}
										itemInCart={(itemId) => this.itemInCart(itemId)}
										removeFromCart={(itemId) => this.removeFromCart(itemId)} />
								</Restricted>
							} />
							<Route path={'/orders'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Orders parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/orders/:cartId'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Order parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/confirm'} element={
								<Confirm parent={this} user={this.props.user} message={this.state.confirm} />
							} />
							<Route path={'/help'} element={
								<Help parent={this} user={this.props.user} />
							} />
						</Routes>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

// routes defined here so there are no accidents when removing them!
const routes = {
	bottles: '/Reuse/Bottles',
	home: '/Reuse/Home',
	orders: '/Reuse/Orders',
	search: '/Reuse/Search'
};

const treeMenuItems = [
	{
		label: 'Home',
		route: routes.home
	},
	{
		label: 'Search',
		route: routes.search
	},
	{
		label: 'Bottles',
		route: routes.bottles
	},
	{
		label: 'Orders',
		route: routes.orders
	},
	{
		label: 'Admin',
		action: '/chempy/login'
	}
];

export default withReuseDefaultRoute(Reuse);