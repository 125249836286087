import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withFASDefaultRoute } from './withFASDefaultRoute';
import ChemHeader from '../ChemHeader';
import Confirm from '../Confirm';
import TreeMenu from '../TreeMenu';
import Restricted from '../Restricted';
import Help from './Help';
import Home from './Home';
import UserHome from './UserHome';
import AdminHome from './AdminHome';

class FAS extends ChemComponent {
  	constructor(props) {
		super(props);

		// copy menu items so we can edit
		var stateTreeMenuItems = this.copyTreeMenuItems(treeMenuItems);
		
		// if the user is not authorized for fas
		if (!props.user.fas || props.user.fas.fasAuthLevel < 1) {
			// remove all liquid air menu items
			// this.removeRoute(stateTreeMenuItems, routes.cylinders);
		}
		
		this.state = {
			fullScreen: false,
			treeMenuItems: stateTreeMenuItems,
			confirm: ''
		};
	}
	
	removeRoute(menuItems, route) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRoute(menuItems[i].nodes, route);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].route && menuItems[i].route.toUpperCase() === route.toUpperCase()) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}
	
	removeRouteByLabel(menuItems, label) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRouteByLabel(menuItems[i].nodes, label);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].label && menuItems[i].label.toUpperCase() === label.toUpperCase()) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}
	
	showConfirmation(message) {
		var self = this;
		this.mergeState({
			confirm: message
		}, () => self.props.navigate('/FAS/Confirm'));
	}
	
	toggleFullScreen(fullScreen) {
		this.mergeState({ fullScreen: fullScreen });
	}

	render() {
		return (<>
			<ChemHeader parent={this} user={this.props.user} title='MicroAnalytical Lab Requests' fullScreen={this.state.fullScreen} 
				toggleFullScreen={(fullScreen) => this.toggleFullScreen(fullScreen)}
				showHelp={() => this.props.navigate('/FAS/Help', { helpPath: this.props.location.pathname })} />
			<Container fluid style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: '#e6e6e4', height: 'calc(100vh - 64px)', overflow: 'auto' }}>
				<Row>
					{!this.state.fullScreen && <Col xs={2}>
						<TreeMenu parent={this} menuItems={this.state.treeMenuItems} selectedRoute={this.props.location.pathname} />
					</Col>}
					<Col xs={this.state.fullScreen ? 12 : 10}>
						<Routes>
							<Route path={'/confirm'} element={<Confirm parent={this} user={this.props.user} message={this.state.confirm} />} />
							<Route path={'/help'} element={<Help parent={this} user={this.props.user} />} />
							<Route path={'/home'} element={<Home parent={this} user={this.props.user} logIn={(action, username, password, fnSuccess) => 
								this.props.logIn(JSON.stringify({ Action: action, Username: username, Password: password }), fnSuccess)} />} />
							<Route path={'/userhome'} element={
								<Restricted authorized={this.props.user.fas && this.props.user.fas.authLevel > 0}>
									<UserHome parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/adminhome'} element={
								<Restricted authorized={this.props.user.fas && this.props.user.fas.authLevel === 1}>
									<AdminHome parent={this} user={this.props.user} />
								</Restricted>
							} />
						</Routes>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

// routes defined here so there are no accidents when removing them!
const routes = {
	home: '/FAS/Home'
};

const treeMenuItems = [
	{
		label: 'Home',
		route: routes.home
	}
];

export default withFASDefaultRoute(FAS);
